import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import { register } from "../../api/authApi";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.svg";
import GoogleAuthButton from "./GoogleAuthButton";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password1: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If email is being changed, automatically update first_name
    if (name === "email") {
      const firstName = value.split("@")[0] || "";
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        first_name: firstName,
        last_name: "", // Always keep last_name as empty string
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validateForm = () => {
    const { email, password1, password2 } = formData;
    const formErrors = {};

    if (!email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email is not valid";
    }
    if (!password1) {
      formErrors.password1 = "Password is required";
    } else if (password1.length < 8) {
      formErrors.password1 = "Password must be at least 8 characters";
    }
    if (!password2) {
      formErrors.password2 = "Confirm password is required";
    } else if (password1 !== password2) {
      formErrors.password2 = "Passwords do not match";
    }

    return formErrors;
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      try {
        setIsSigningUp(true);
        const response = await register(formData);
        if (response.success) {
          setSignupSuccess(true);
        } else {
          let error = JSON.stringify(response.error.data);
          setErrors({ general: error });
          toast.error(error);
        }
      } catch (error) {
        setErrors({
          general: "An unexpected error occurred. Please try again.",
        });
        toast.error("An unexpected error occurred. Please try again.");
        console.log(error);
      } finally {
        setIsSigningUp(false);
      }
    } else {
      setErrors(formErrors);
    }
  };

  if (signupSuccess) {
    return (
      <div className="min-h-screen bg-[#222226] flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-[#333537] rounded-xl shadow-lg p-8 text-center">
          <h3 className="text-2xl font-semibold text-white mb-6">
            Account Created Successfully!
          </h3>
          <p className="text-gray-300 mb-4">
            Thank you for signing up. We've sent a verification email to your
            inbox.
          </p>
          <p className="text-gray-300 mb-6">
            Please check your email and click the verification link to activate
            your account.
          </p>
          <Link
            to="/"
            className="inline-block w-full bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
          >
            Back to Login
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#222226] flex flex-col items-center justify-center px-4">
      <div className="w-full max-w-md">
        {/* Logo Section */}
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Logo" className="h-20 w-auto" />
        </div>

        {/* Signup Form Card */}
        <div className="bg-[#333537] rounded-xl shadow-lg p-8">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-semibold text-white mb-2">
              Create an Account
            </h2>
            <p className="text-gray-400">
              Please fill in the details to create an account
            </p>
          </div>

          {/* Add Google Auth Button at the top */}
          <GoogleAuthButton />

          <form onSubmit={handleSignup} className="space-y-4">
            {/* Email */}
            <div>
              <p className="block text-sm font-medium text-gray-200 mb-2">
                Email
              </p>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                placeholder="Enter your email"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-500">{errors.email}</p>
              )}
            </div>

            {/* Password */}
            <div>
              <p className="block text-sm font-medium text-gray-200 mb-2">
                Password
              </p>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password1"
                  value={formData.password1}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                  placeholder="Create a password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
              {errors.password1 && (
                <p className="mt-1 text-sm text-red-500">{errors.password1}</p>
              )}
            </div>

            {/* Confirm Password */}
            <div>
              <p className="block text-sm font-medium text-gray-200 mb-2">
                Confirm Password
              </p>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="password2"
                  value={formData.password2}
                  onChange={handleChange}
                  className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                  placeholder="Confirm your password"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
              {errors.password2 && (
                <p className="mt-1 text-sm text-red-500">{errors.password2}</p>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSigningUp}
              className="w-full bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed mt-6"
            >
              {isSigningUp ? "Signing up..." : "Sign up"}
            </button>

            {/* Login Link */}
            <div className="text-center text-sm text-gray-400 mt-4">
              Already have an account?{" "}
              <Link to="/" className="text-purple-500 hover:text-purple-400">
                Sign in
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
