import { React, useState } from "react";
import { Image, Sparkles } from "lucide-react";
import { DownloadButton } from "../../Utils/DownloadButton.jsx";
import FileUploader from "../FileUploader.jsx";
import { useSelector } from "react-redux";
import FilterForm from "../FilterForm.jsx";
import GenerateField from "./GenerateField.jsx";
import ImagePlaceholder from "./ImagePlaceholder.jsx";

const ImageProcessorGenerator = ({
  title,
  uploadTitle,
  onRun,
  appliedFilter,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
  gen,
}) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`${isSidebarOpen ? "ml-52" : ""} bg-[#222226] transition-all duration-200  rounded-xl`}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>
      <div className="flex flex-col lg:flex-row ">
        <GenerateField title={title} gen={gen} />

        <div className="w-full min-h-screen bg-black">
          <div className="flex flex-co items-center justify-center md:flex-row gap-4">
            <div className="w-full md:w-1/2 relative rounded-lg py-4">
              <h2 className="text-lg text-white text-center mb-4">
                Output Image
              </h2>
              <div className="aspect-square w-full mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <h1 className="text-xl text-yellow-400">
                        Generation in Process
                      </h1>
                    </div>
                    <div className="loader"></div>
                  </div>
                ) : appliedFilter ? (
                  <img
                    src={appliedFilter}
                    alt="filtered"
                    className="w-full h-full object-contain rounded-lg cursor-pointer"
                    onClick={() => openFullscreenImage(appliedFilter)}
                  />
                ) : (
                  <ImagePlaceholder />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className=" lg:w-[600px] mt-8">
          <div className="mt-8 px-8 pb-4 flex border-b border-[#414141] flex-col justify-center gap-2 w-full">
            <div className=" w-full">
              <button
                onClick={onRun}
                className="text-white w-full bg-[#333537] py-3 px-8 rounded-md cursor-pointer hover:bg-[#474a4d] transition-colors flex gap-2 items-center justify-center"
              >
                <Sparkles size={16} />
                <span className="text-sm">
                  {isRunning ? "Generating..." : "Generate"}
                </span>
              </button>
              <p className="text-gray-500 text-sm mt-2">Uses 2 Credit</p>
            </div>
            <div>
              <DownloadButton imageUrl={appliedFilter} />
              <p className="text-gray-500 text-sm mt-2">Uses 1 Credit</p>
            </div>
          </div>
          <div className="px-8 mt-8">
            <h1 className="text-sm text-white">SKU ID:</h1>
            <div className="border w-full px-2 py-2 mt-3 rounded-sm">
              <input
                type="text"
                placeholder="Enter your ID number"
                className="bg-transparent placeholder:text-sm h-full text-sm w-full text-white"
              />
            </div>
            <button className=" bg-gradient-to-r from-[#5345E6] to-[#613AB1] mt-3 text-white px-8 py-1 rounded-sm">
              Save
            </button>
          </div>
        </div>

        {/* Your existing modals remain the same */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageProcessorGenerator;
