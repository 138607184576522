import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getGoogleRedirectURL,
  validateGoogleRedirectCodeAndLogin,
} from "../../api/authApi";

const GoogleAuthButton = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const processingCallback = useRef(false);

  useEffect(() => {
    const handleCallback = async () => {
      if (
        location.pathname === "/social/google/login/callback/" &&
        !processingCallback.current
      ) {
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get("code");

        if (code) {
          try {
            // Set the flag to prevent duplicate processing
            processingCallback.current = true;

            // Decode the URL-encoded code before sending to the API
            const decodedCode = decodeURIComponent(code);
            console.log("Processing Google login with code");

            const response =
              await validateGoogleRedirectCodeAndLogin(decodedCode);

            if (response.success) {
              navigate("/dashboard/home");
            } else {
              console.error("Google login failed:", response.error);
              navigate("/login");
            }
          } catch (error) {
            console.error("Error during Google login:", error);
            navigate("/login");
          }
        } else {
          console.log("No code found in URL parameters");
          navigate("/login");
        }
      }
    };

    handleCallback();
  }, [location.pathname]); // Only depend on pathname changes

  const handleGoogleSignIn = async () => {
    try {
      const response = await getGoogleRedirectURL();
      if (response.success && response.data.google_oauth_url) {
        window.location.href = response.data.google_oauth_url;
      } else {
        console.error("Failed to get Google OAuth URL");
      }
    } catch (error) {
      console.error("Error initiating Google sign-in:", error);
    }
  };

  return (
    <div className={className}>
      {/* Google Button */}
      <button
        type="button"
        className="w-full flex items-center justify-center gap-2 bg-[#222226] text-white py-2 px-4 rounded-lg hover:bg-[#2a2a2e] transition-colors border border-gray-700"
        onClick={handleGoogleSignIn}
      >
        <svg className="w-5 h-5" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          />
          <path
            fill="currentColor"
            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          />
          <path
            fill="currentColor"
            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          />
          <path
            fill="currentColor"
            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          />
        </svg>
        Continue with Google
      </button>
      {/* Divider */}
      <div className="relative mt-6 mb-6">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-700"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 text-gray-400 bg-[#333537]">Or</span>
        </div>
      </div>
    </div>
  );
};

export default GoogleAuthButton;
