import { generateUniqueFilename } from "../../../utils/utils";
import { Stage, Layer, Image, Transformer } from "react-konva";
import { React, useState, useEffect, useRef } from "react";
const EditableImage = ({
  backgroundSrc,
  modelSrc,
  setStageRef,
  containerWidth = 300,
  containerHeight = 380,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [showShadowDropdown, setShowShadowDropdown] = useState(false);
  const [containerSize, setContainerSize] = useState({
    width: containerWidth,
    height: containerHeight,
  });
  const imageRef = useRef(null);
  const trRef = useRef(null);
  const stageRef = useRef(null);
  const [activeTab, setActiveTab] = useState("shadowOptions");
  const [isDraggable, setIsDraggable] = useState(false);
  const [lightDirection, setLightDirection] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [modelImage, setModelImage] = useState(null);
  const [isBackgroundSelected, setIsBackgroundSelected] = useState(false);
  const [glowEnabled, setGlowEnabled] = useState(true);
  const backgroundTrRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("#0e3b4b");
  const [shadowProps, setShadowProps] = useState({
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowBlur: 5,
    shadowOpacity: 0.6,
    shadowOffsetX: 28,
    shadowOffsetY: -2,
  });

  const handleTabChange = (tab = "dropShadow") => {
    setActiveTab(tab);

    if (tab === "dropShadow") {
      setShadowProps({
        shadowColor: "rgba(0, 0, 0, 0.5)",
        shadowBlur: 5,
        shadowOpacity: 0.6,
        shadowOffsetX: 28,
        shadowOffsetY: -2,
      });
    } else if (tab === "aiAngle") {
      setShadowProps({
        shadowColor: "rgba(0, 0, 0, 0)",
        shadowBlur: 0,
        shadowOpacity: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      });
    }
  };
  const loadImage = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.crossOrigin = "anonymous";
      img.src = src;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  useEffect(() => {
    if (backgroundSrc?.newImageUrl) {
      loadImage(backgroundSrc.newImageUrl)
        .then((img) => {
          setBackgroundImage(img);
        })
        .catch((err) => console.error(err));
    }
    if (modelSrc) {
      loadImage(modelSrc)
        .then(setModelImage)
        .catch((err) => console.error(err));
    }

    if (setStageRef) {
      setStageRef(stageRef.current);
    }
  }, [backgroundSrc, modelSrc, setStageRef]);
  useEffect(() => {
    if (backgroundRef.current && backgroundTrRef.current) {
      if (isBackgroundSelected) {
        backgroundTrRef.current.nodes([backgroundRef.current]);
      } else {
        backgroundTrRef.current.nodes([]);
      }
      backgroundTrRef.current.getLayer().batchDraw();
    }
  }, [isBackgroundSelected]);

  useEffect(() => {
    if (imageRef.current && trRef.current) {
      if (isSelected) {
        trRef.current.nodes([imageRef.current]);
      } else {
        trRef.current.nodes([]);
      }
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    setContainerSize({
      width: containerWidth,
      height: containerHeight,
    });
  }, [containerWidth, containerHeight]);

  const handleDeselect = (e) => {
    if (e.target === stageRef.current) {
      setIsSelected(false);
    }
  };

  const handleDownload = () => {
    if (!stageRef.current) {
      console.error("Stage reference is not available for download.");
      return;
    }
    setIsSelected(false);
    setTimeout(() => {
      const dataURL = stageRef.current.toDataURL({
        pixelRatio: 2,
      });
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = generateUniqueFilename(".png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1000);
  };
  const backgroundRef = useRef();
  const [backgroundProps, setBackgroundProps] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });

  const handleDoubleClick = () => {
    setIsDraggable(!isDraggable);

    if (!backgroundImage) return;

    const containerRatio = containerSize.width / containerSize.height;
    const imageRatio = backgroundImage.width / backgroundImage.height;

    let scale;
    if (containerRatio > imageRatio) {
      scale = containerSize.width / backgroundImage.width;
    } else {
      scale = containerSize.height / backgroundImage.height;
    }

    setBackgroundProps({
      x: 0,
      y: 0,
      scale,
    });
  };

  useEffect(() => {
    if (backgroundImage) {
      const containerRatio = containerSize.width / containerSize.height;
      const imageRatio = backgroundImage.width / backgroundImage.height;

      let scale;
      if (containerRatio > imageRatio) {
        scale = containerSize.width / backgroundImage.width;
      } else {
        scale = containerSize.height / backgroundImage.height;
      }

      setBackgroundProps({
        x: (containerSize.width - backgroundImage.width * scale) / 2,
        y: (containerSize.height - backgroundImage.height * scale) / 2,
        scale,
      });
    }
  }, [backgroundImage, containerSize]);

  const handleDragMove = (e) => {
    const { x, y } = e.target.position();
    const scaledWidth = backgroundImage.width * backgroundProps.scale;
    const scaledHeight = backgroundImage.height * backgroundProps.scale;

    const newX = Math.max(Math.min(x, 0), containerSize.width - scaledWidth);
    const newY = Math.max(Math.min(y, 0), containerSize.height - scaledHeight);

    setBackgroundProps((prev) => ({
      ...prev,
      x: newX,
      y: newY,
    }));
  };
  return (
    <div className="h-fit">
      <div
        className=" w-full  -ml-16 border-blue-800"
        style={{
          width: `${containerSize.width}px`,
          height: `${containerSize.height}px`,
        }}
      >
        <Stage
          ref={stageRef}
          width={containerSize.width}
          height={containerSize.height}
          onMouseDown={handleDeselect}
          style={{
            backgroundImage,
          }}
          pixelRatio={window.devicePixelRatio || 1}
        >
          <Layer>
            {backgroundImage && (
              <>
                <Image
                  ref={backgroundRef}
                  image={backgroundImage}
                  x={backgroundProps.x}
                  y={backgroundProps.y}
                  width={backgroundImage.width * backgroundProps.scale}
                  height={backgroundImage.height * backgroundProps.scale}
                  draggable={isDraggable}
                  onDragMove={handleDragMove}
                  onDblClick={handleDoubleClick}
                  onClick={(e) => {
                    e.cancelBubble = true;
                    setIsBackgroundSelected(true);
                    setIsSelected(false);
                  }}
                  onTransformEnd={(e) => {
                    const node = backgroundRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    setBackgroundProps((prev) => ({
                      ...prev,
                      x: node.x(),
                      y: node.y(),
                      scale: Math.min(scaleX, scaleY),
                    }));

                    node.scaleX(1);
                    node.scaleY(1);
                  }}
                />
                <Transformer
                  ref={backgroundTrRef}
                  anchorSize={6}
                  enabledAnchors={[
                    "top-left",
                    "top-right",
                    "bottom-left",
                    "bottom-right",
                  ]}
                  rotateEnabled={false}
                  boundBoxFunc={(oldBox, newBox) => {
                    if (newBox.width < 30 || newBox.height < 30) {
                      return oldBox;
                    }
                    return newBox;
                  }}
                />
              </>
            )}
            {modelImage && (
              <>
                <Image
                  ref={imageRef}
                  image={modelImage}
                  draggable
                  x={containerSize.width * 0.1}
                  y={containerSize.height * 0.1}
                  width={
                    (containerSize.height * 0.8 * modelImage.width) /
                    modelImage.height
                  }
                  height={containerSize.height * 0.8}
                  shadowColor={shadowProps.shadowColor}
                  shadowBlur={shadowProps.shadowBlur}
                  shadowOffsetX={shadowProps.shadowOffsetX}
                  shadowOffsetY={shadowProps.shadowOffsetY}
                  shadowOpacity={shadowProps.shadowOpacity}
                  onClick={(e) => {
                    e.cancelBubble = true;
                    setIsSelected(true);
                  }}
                  onTap={(e) => {
                    e.cancelBubble = true;
                    setIsSelected(true);
                  }}
                />
              </>
            )}
            <Transformer
              ref={trRef}
              anchorSize={6}
              enabledAnchors={[
                "top-left",
                "top-right",
                "bottom-left",
                "bottom-right",
              ]}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                if (newBox.width < 30 || newBox.height < 30) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          </Layer>
        </Stage>
      </div>
      <div className="mt-3 relative">
        <div className="flex items-center w-max  ml-11 ">
          <button
            onClick={() => {
              setShowShadowDropdown(!showShadowDropdown);
              handleTabChange();
            }}
            className="px-4 py-2 bg-gray-800 text-white rounded-lg"
          >
            {showShadowDropdown ? "Hide Shadow Options" : "Show Shadow Options"}
          </button>
        </div>

        {showShadowDropdown && (
          <>
            <div className=" mt-2 space-y-4 space-x-2 -ml-4 bg-gray-800 z-10 border-2 rounded-md border-dashed  w-[335px]">
              {/* <div className="flex items-center mt-2 ml-7 space-x-2">
                <button
                  onClick={() => handleTabChange("dropShadow")}
                  className={`px-2 py-2 rounded-md ${activeTab === "dropShadow"
                      ? "bg-purple-800 text-white"
                      : "bg-gray-800 text-gray-300"
                    }`}
                >
                  Drop Shadow
                </button>
                <button
                  onClick={() => handleTabChange("aiAngle")}
                  className={`px-2 py-2 rounded-md ${activeTab === "aiAngle"
                      ? "bg-purple-800 text-white"
                      : "bg-gray-800 text-gray-300"
                    }`}
                >
                  AI Shadow
                </button>
              </div> */}
              {activeTab === "dropShadow" && (
                <>
                  <div className="m-2">
                    <label htmlFor="horizontal">Horizontal Offset:</label>
                    <input
                      id="horizontal"
                      type="range"
                      min={-50}
                      max={50}
                      value={shadowProps.shadowOffsetX}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowOffsetX: parseInt(e.target.value, 10),
                        }))
                      }
                      className="ml-2"
                    />
                    <span className="ml-2 text-white">
                      {shadowProps.shadowOffsetX}px
                    </span>
                  </div>

                  <div className="m-2">
                    <label htmlFor="vertical" className="">
                      Vertical Offset:
                    </label>
                    <input
                      id="vertical"
                      type="range"
                      min={-50}
                      max={50}
                      value={shadowProps.shadowOffsetY}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowOffsetY: parseInt(e.target.value, 10),
                        }))
                      }
                      className="ml-6"
                    />
                    <span className="ml-2 text-white">
                      {shadowProps.shadowOffsetY}px
                    </span>
                  </div>

                  <div className="m-2">
                    <label htmlFor="blur" className="">
                      Blur:
                    </label>
                    <input
                      id="blur"
                      type="range"
                      min={0}
                      max={100}
                      value={shadowProps.shadowBlur}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowBlur: parseInt(e.target.value, 10),
                        }))
                      }
                      className="ml-24"
                    />
                    <span className="ml-2 text-white">
                      {shadowProps.shadowBlur}
                    </span>
                  </div>

                  <div className="m-2">
                    <label htmlFor="opacity" className="">
                      Opacity:
                    </label>
                    <input
                      id="opacity"
                      type="range"
                      min={0}
                      max={1}
                      step={0.01}
                      value={shadowProps.shadowOpacity}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowOpacity: parseFloat(e.target.value),
                        }))
                      }
                      className="ml-[68px]"
                    />
                    <span className="ml-2 text-white">
                      {(shadowProps.shadowOpacity * 100).toFixed(0)}%
                    </span>
                  </div>
                  <div className="m-2">
                    <div className="flex items-center space-x-2 ml-4">
                      {[
                        "#FFFFFF",
                        "#8B0000",
                        "#0074D9",
                        "#A0522D",
                        "#000000",
                        "#008080",
                      ].map((color, index) => (
                        <button
                          key={index}
                          className="w-8 h-8 rounded-full border-2 border-white"
                          style={{
                            background: color,
                            backgroundColor: color.includes("gradient")
                              ? undefined
                              : color,
                          }}
                          onClick={() =>
                            setShadowProps((prev) => ({
                              ...prev,
                              shadowColor: color.includes("gradient")
                                ? "transparent"
                                : color,
                            }))
                          }
                        />
                      ))}
                      <div className="relative">
                        <button
                          className="w-8 h-8 rounded-full border-2 mt-1 border-white"
                          style={{
                            background:
                              "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
                          }}
                          onClick={() =>
                            document.getElementById("customColorPicker").click()
                          }
                        ></button>
                        <input
                          type="color"
                          id="customColorPicker"
                          className="absolute inset-0 w-0 h-0 opacity-0"
                          onChange={(e) =>
                            setShadowProps((prev) => ({
                              ...prev,
                              shadowColor: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeTab === "aiAngle" && (
                <>
                  <div className="mt-2 bg-gray-800 rounded-md text-white">
                    <div className="mb-2">
                      <label>Light Direction:</label>
                      <input
                        type="range"
                        min="0"
                        max="360"
                        // value={groundShadowProps.shadowAngle}
                        // onChange={(e) =>
                        //   updateGroundShadow(parseInt(e.target.value, 10))
                        // }
                        className="ml-6"
                      />
                      <span className="ml-2">
                        {/* {groundShadowProps.shadowAngle}°
                         */}
                        0°
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 mb-2">
                    <label>Angle Opacity:</label>
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      // value={groundShadowProps.shadowOpacity}
                      // onChange={(e) =>
                      //   setGroundShadowProps((prev) => ({
                      //     ...prev,
                      //     shadowOpacity: parseFloat(e.target.value),
                      //   }))
                      // }
                      className="ml-6"
                    />
                    <span className="ml-2">
                      0%
                      {/* {(groundShadowProps.shadowOpacity * 100).toFixed(0)}
                      % */}
                    </span>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <button
          onClick={handleDownload}
          className="mt-4 px-4 py-2 ml-[68px] bg-blue-500 text-white rounded-lg"
        >
          Merge Image
        </button>
      </div>
    </div>
  );
};
export default EditableImage;
