import React, { useState, useRef } from "react";
import { UploadCloud, UploadIcon } from "lucide-react";

const FileUploader = ({ handleImageUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      console.error("No files selected");
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => setPreview(e.target.result);
    reader.readAsDataURL(file);
    handleImageUpload(e);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      onClick={handleClick}
      className="flex gap-4 cursor-pointer text-white bg-generate-gradient bottom-2 rounded-lg px-4 w-full py-3 justify-center items-center"
    >
      <div>
        <UploadIcon />
      </div>
      <div className="flex flex-col items-center rounded-lg">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
        />
        <span className="text-base">Upload Image</span>
      </div>
    </div>
  );
};

export default FileUploader;
