import React from "react";
import download from "../../assets/images/download.svg";
import { axiosInstanceUnAuthenticated } from "../../api/axiosConfig";
import { Download } from "lucide-react";

export const DownloadButton = ({ imageUrl }) => {
  const downloadImage = async () => {
    const response = await axiosInstanceUnAuthenticated.get(imageUrl, {
      responseType: "blob",
    });
    const fileName = imageUrl.split("/").pop();
    const downloadUrl = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.download = fileName;
    link.href = downloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    // <button onClick={downloadImage} className="download-btn">
    //   Download Image
    // </button>
    <button
      onClick={downloadImage}
      disabled={!imageUrl}
      className=" text-black bg-customYellow w-full py-3 px-8 rounded-md cursor-pointer hover:opacity-70 transition-opacity flex gap-2 items-center justify-center"
    >
      <Download size={16} />
      <span className="text-sm">Download</span>
    </button>
  );
};
