import React, { useState } from "react";
import { Link } from "react-router-dom";
import { requestPasswordReset } from "../../api/authApi";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.svg";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [sending, setIsSending] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setIsSending(true);

    try {
      const response = await requestPasswordReset(email);
      setIsSubmitted(true);
      toast.success("Password reset email sent successfully");
    } catch (error) {
      let errorMessage = "An error occurred. Please try again.";
      if (error.response) {
        if (
          error.response.data.email &&
          error.response.data.email[0] ===
            "User with this email does not exist."
        ) {
          errorMessage = "User with this email does not exist.";
        } else {
          errorMessage =
            error.response.data.message ||
            error.response.data.detail ||
            errorMessage;
        }
      } else if (error.request) {
        errorMessage =
          "No response received from server. Please try again later.";
      }
      toast.error(errorMessage);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#222226] flex flex-col items-center justify-center px-4">
      <div className="w-full max-w-md">
        {/* Logo Section */}
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Logo" className="h-20 w-auto" />
        </div>

        {/* Forgot Password Card */}
        <div className="bg-[#333537] rounded-xl shadow-lg p-8">
          <div className="text-center mb-6">
            {!isSubmitted ? (
              <>
                <h2 className="text-2xl font-semibold text-white mb-2">
                  Forgot Password
                </h2>
                <p className="text-gray-400">
                  Enter your email address to reset your password
                </p>
              </>
            ) : (
              <>
                <h2 className="text-2xl font-semibold text-white mb-2">
                  Check Your Email
                </h2>
                <p className="text-gray-400">
                  We've sent a password reset link to
                  <br />
                  <span className="text-purple-500">{email}</span>
                </p>
              </>
            )}
          </div>

          {!isSubmitted ? (
            <form onSubmit={handlePasswordReset} className="space-y-6">
              {/* Email Field */}
              <div>
                <p className="block text-sm font-medium text-gray-200 mb-2">
                  Email
                </p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                  placeholder="Enter your email"
                  required
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={sending}
                className="w-full bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {sending ? "Sending reset link..." : "Reset Password"}
              </button>

              {/* Back to Sign In */}
              <div className="text-center text-sm text-gray-400">
                Remember your password?{" "}
                <Link to="/" className="text-purple-500 hover:text-purple-400">
                  Sign in
                </Link>
              </div>
            </form>
          ) : (
            <div className="space-y-6">
              <button
                onClick={() => {
                  window.location.href = "/";
                }}
                className="w-full bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity"
              >
                Back to Sign In
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Forgot;
