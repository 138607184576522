import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  FACE_SWAP: {},
  APPAREL_SWAP: {},
  BG_SWAP: {},
  MODEL_GEN: {},
  BG_GEN: {},
  Quick_Catalog: {},
  DESC_GEN: {
    file: null,
    garmentDetails: null,
    error: null,
    isLoading: false,
    previewUrl: null,
  },
  sidebar: {
    isOpen: true,
  },
};

const studioSlice = createSlice({
  name: "studio",
  initialState,
  reducers: {
    updateTabState: (state, action) => {
      const { tabId, newState } = action.payload;
      state[tabId] = { ...state[tabId], ...newState };
    },
    toggleSidebar: (state) => {
      state.sidebar.isOpen = !state.sidebar.isOpen;
    },
    setSidebarState: (state, action) => {
      state.sidebar.isOpen = action.payload;
    },
  },
});

export const { updateTabState, toggleSidebar, setSidebarState } =
  studioSlice.actions;
export default studioSlice.reducer;
