import React from "react";
import { Link } from "react-router-dom";

const BannerCard = ({ id, imageSrc, title, description, link }) => {
  return (
    <Link to={link} className="block">
      <div className="bg-[#333537] rounded-xl shadow-lg p-6 h-full transition-transform hover:scale-[1.02] hover:shadow-xl">
        <div className="flex flex-col h-full">
          {/* Image container with fixed height */}
          <div className="relative h-40 mb-4">
            <img
              src={imageSrc}
              alt={title}
              className="absolute inset-0 w-full h-full object-contain"
            />
          </div>

          {/* Text content */}
          <div className="flex-1 flex flex-col">
            <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
            <p className="text-gray-400 text-sm flex-1">{description}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BannerCard;
