"use client";
import { useState } from "react";
import { Check } from "lucide-react";

export default function FilterForm() {
  const [filters, setFilters] = useState({
    gender: "",
    age: [],
    ethnicity: [],
  });

  const handleGenderChange = (value) => {
    setFilters((prev) => ({ ...prev, gender: value }));
  };

  const handleCheckboxChange = (category, value) => {
    setFilters((prev) => ({
      ...prev,
      [category]: prev[category].includes(value)
        ? prev[category].filter((item) => item !== value)
        : [...prev[category], value],
    }));
  };

  const handleReset = () => {
    setFilters({
      gender: "",
      age: [],
      ethnicity: [],
    });
  };

  const handleApplyFilter = () => {
    console.log("Applied filters:", filters);
    alert("filter applied");
  };

  return (
    <div className="w-full max-w-sm space-y-6 rounded-lg bg-[#333537] p-6 text-white">
      <div className="space-y-4">
        <h3 className="text-base">Gender:</h3>
        <div className="flex gap-4">
          {["Male", "Female"].map((option) => (
            <label
              key={option}
              className="flex text-white text-sm items-center space-x-2 cursor-pointer"
            >
              <div className="relative flex items-center">
                <input
                  type="radio"
                  name="gender"
                  value={option.toLowerCase()}
                  checked={filters.gender === option.toLowerCase()}
                  onChange={(e) => handleGenderChange(e.target.value)}
                  className="appearance-none h-4 w-4 rounded-full border border-gray-400 bg-transparent checked:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
                />
                {filters.gender === option.toLowerCase() && (
                  <div className="absolute left-1/2 top-1/2 h-2 w-2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-purple-500" />
                )}
              </div>
              <span>{option}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="space-y-4">
        <h3 className="text-base">Age:</h3>
        <div className="grid grid-cols-2 gap-4">
          {["15-20", "20-25", "25-30", "30-35"].map((range) => (
            <label
              key={range}
              className="flex text-white text-sm items-center space-x-2 cursor-pointer"
            >
              <div className="relative flex items-center">
                <input
                  type="checkbox"
                  value={range}
                  checked={filters.age.includes(range)}
                  onChange={(e) => handleCheckboxChange("age", e.target.value)}
                  className="appearance-none h-4 w-4 rounded border border-gray-400 bg-transparent checked:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
                />
                {filters.age.includes(range) && (
                  <Check
                    className="absolute left-0 top-0 h-4 w-4 text-purple-500"
                    size={16}
                  />
                )}
              </div>
              <span>{range}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="space-y-4">
        <h3 className="text-base">Ethnicity:</h3>
        <div className="grid grid-cols-2 gap-4">
          {["Indian", "Asian", "African", "American"].map((ethnicity) => (
            <label
              key={ethnicity}
              className="flex text-sm text-white items-center space-x-2 cursor-pointer"
            >
              <div className="relative flex items-center">
                <input
                  type="checkbox"
                  value={ethnicity.toLowerCase()}
                  checked={filters.ethnicity.includes(ethnicity.toLowerCase())}
                  onChange={(e) =>
                    handleCheckboxChange("ethnicity", e.target.value)
                  }
                  className="appearance-none h-4 w-4 rounded border border-gray-400 bg-transparent checked:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
                />
                {filters.ethnicity.includes(ethnicity.toLowerCase()) && (
                  <Check
                    className="absolute left-0 top-0 h-4 w-4 text-purple-500"
                    size={16}
                  />
                )}
              </div>
              <span>{ethnicity}</span>
            </label>
          ))}
        </div>
      </div>
      <div className="flex gap-4">
        <button
          onClick={handleReset}
          className="flex-1 rounded-md border bg-transparent px-4 py-2 text-white hover:bg-purple-500/10 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
        >
          Reset
        </button>
        <button
          onClick={handleApplyFilter}
          className="flex-1 rounded-md bg-gradient-to-r from-[#5345E6] to-[#613AB1] px-4 py-2 text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-zinc-900"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
}
