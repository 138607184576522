
import React from "react";

const PhotoLibrary = ({
  images,
  selectedFace,
  handleFaceImageSelect,
  renderLoadingPlaceholders,
}) => {
  return (
    <div className=" bg-[#222226] rounded-lg p-2">
      <div 
        className="grid grid-cols-3 gap-2 overflow-y-auto"
        style={{ 
          maxHeight: "300px",
          scrollbarWidth: "thin",
          scrollbarColor: "#666 #222226"
        }}
      >
        {images && images.length > 0 ? (
          images.map((img, index) => (
            <div
              key={index}
              className={`aspect-square relative rounded-lg overflow-hidden bg-[#2A2A2E] ${
                selectedFace === img ? "ring-2 ring-yellow-400" : ""
              }`}
            >
              <img
                src={img}
                className="w-full h-full object-cover cursor-pointer"
                alt={`Image ${index + 1}`}
                onClick={() => handleFaceImageSelect(img)}
              />
              {selectedFace === img && (
                <div className="absolute inset-0 border-2 border-yellow-400 rounded-lg" />
              )}
            </div>
          ))
        ) : (
          <>{renderLoadingPlaceholders()}</>
        )}
      </div>
    </div>
  );
};

export default PhotoLibrary;