import React, { useState, useEffect } from "react";
import { UploadCloud } from "lucide-react";

const App = () => {
  const [alertVisible, setAlertVisible] = useState(false);
  const [clothImage, setClothImage] = useState(null);
  const [selectedModelImage, setSelectedModelImage] = useState(null);
  const [outputImage, setOutputImage] = useState(null);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);

  useEffect(() => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [alertVisible]);

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      const imageURL = URL.createObjectURL(blobImage);
      console.log("Uploaded Image URL:", imageURL);
      setClothImage({ blobImage: blobImage, blobImageUrl: imageURL });
      setSelectedModelImage(null);
      setOutputImage(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
    }
  };

  const handleClothingSelect = (clothing) => {
    setSelectedModelImage(clothing);
    setWarningMessage("");
    setAlertVisible(false);
  };

  const handleRun = () => {
    if (isRunning) return;
    setIsRunning(true);
    setShowProgressBar(true);
    setTimeout(() => {
      setShowProgressBar(false);
      setIsRunning(false);
    }, 8000);
  };

  const handleDownload = async () => {
    if (isDownloadReady) {
      console.log("Download started...");
    } else {
      console.log("No file ready to download.");
    }
  };

  return (
    <div className="app-container">
      <FileUploader handleImageUpload={handleImageUpload} />
      <button
        onClick={handleRun}
        className="mt-4 bg-purple-600 text-white py-2 px-4 rounded"
      >
        Run Process
      </button>
      {showProgressBar && <p>Progress: Running...</p>}
    </div>
  );
};

const FileUploader = ({ handleImageUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleImageUpload({ target: { files } });
    }
  };

  return (
    <div
      className={`flex w-max p-2 justify-center items-center`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div
        className={`border-2 flex flex-col items-center ${
          isDragOver ? "border-purple-600" : "border-dashed border-gray-300"
        } rounded-lg p-4 w-full max-w-md`}
      >
        <div className="flex items-center justify-center mb-4">
          <UploadCloud
            size={56}
            className={`${isDragOver ? "text-purple-600" : "text-gray-500"}`}
          />
        </div>
        <h1 className="text-sm text-gray-500 font-medium mb-4">
          {isDragOver
            ? "Drop your file here"
            : "Select a file or drag and drop here"}
        </h1>
        <p className="text-gray-500 text-sm mb-6">
          JPG, PNG or WEBP files are supported
        </p>
        <div className="w-full flex items-center justify-center mt-4">
          <label className="relative inline-block">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
            />
            <span className="bg-purple-600 text-white py-2 px-4 rounded-md cursor-pointer hover:bg-purple-700 transition-colors">
              SELECT FILE
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
