export const taskStatus = {
  QUEUED: "QUEUED",
  IN_PROCESS: "IN_PROCESS",
  STARTING: "STARTING",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  EMPTY_OUTPUT: "EMPTY_OUTPUT",
};

export const AIFeatureCodes = {
  FACE_SWAP: "FACE_SWAP",
  BG_SWAP: "BG_SWAP",
  APPAREL_SWAP: "APPAREL_SWAP",
  // MODEL_SWAP: "MODEL_SWAP",
  // POSTURE_CHANGE: "POSTURE_CHANGE",
  // MQUINM: "MQUINM",
  // CMQUIN: "CMQUIN",
  DESC_GEN: "DESC_GEN",
  // MODEL_GEN: "MODEL_GEN",
  // BG_GEN: "BG_GEN",
  // UPSCALER: "UPSCALER",
  // CATALOGUER: "CATALOGUER",
  // INPAINT: "INPAINT",
  // OUTPAINT: "OUTPAINT",
};

export const AIFeatureViewNames = {
  FACE_SWAP: "Face Swap",
  BG_SWAP: "Background Swap",
  APPAREL_SWAP: "Apparel Swap",
  BG_EDIT: "Background Editor",
  MODEL_SWAP: "Model Swap",
  // POSTURE_CHANGE: "Posture Change",
  // MQUINM: "Mannequin to Model",
  // CMQUIN: "Cloth to Mannequin",
  DESC_GEN: "Description Generator",
  MODEL_GEN: "Model Generator",
  BG_GEN: "Background Generator",
  // UPSCALER: "Upscaler",
  CATALOGUER: "Cataloguer",
  // INPAINT: "Image Inpainting",
  // OUTPAINT: "Image Outpainting",
};

export const Colors = {
  primary: "#F2F2F2",
  secondary: "#FBBF24",
  textLight: "#F9FAFB",
  textDark: "#4B5563",
  backgroundLight: "#FFFFFF",
  backgroundDark: "#1F2937",
  border: "#D1D5DB",
  error: "#DC2626",
  success: "#22C55E",
  neutral: "#6B7280",
  hoverColor: "#1D3A72",
};

export const GenderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const EthnicityOptions = [
  { label: "Indian", value: "indian" },
  { label: "American", value: "american" },
  { label: "African", value: "african" },
  { label: "Asian", value: "asian" },
];
export const ImageTypeCodes = {
  FACE: "FACE",
  MODEL_FULL: "MODEL_FULL",
  MODEL_UPPER_HALF: "MODEL_UPPER_HALF",
  MODEL_LOWER_HALF: "MODEL_LOWER_HALF",
  BACKDROP: "BACKDROP",
};

export const ImageTypeViewNames = {
  FACE: "Face",
  MODEL_UPPER_HALF: "Upper Body",
  MODEL_LOWER_HALF: "Lower Body",
  MODEL_FULL: "Model Full Body",
  BACKDROP: "Backdrop",
};
