import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Studio = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to /dashboard/faceswap immediately when the component mounts
    navigate("/dashboard/faceswap");
  }, []); // Empty dependency array ensures this runs only once on component mount

  // Since we're immediately redirecting, we can return null
  return null;
};

export default Studio;
