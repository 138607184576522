import React, { useState, useRef } from "react";
import {
  Image,
  Play,
  Download,
  Merge,
  Info,
  ChevronDown,
  X,
} from "lucide-react";
import {
  postVideoGenerator,
  getTaskStatusVideo,
} from "../../api/modelsApi.jsx";

export default function ImageToVideo() {
  const [selectedPrompt, setSelectedPrompt] = useState("");
  const [textboxPrompt, setTextboxPrompt] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoLink, setVideoLink] = useState(null);
  const [isCustomPrompt, setIsCustomPrompt] = useState(false);
  const [taskStatus, setTaskStatus] = useState(null);
  const [duration, setDuration] = useState(5);
  const fileInputRef = useRef(null);
  const [availablePrompts] = useState([
    "model walking confidently towards camera, slighly smiling, looking into camera",
    "camera slightly zooms. natural motion",
  ]);

  const handleFileSelect = (files) => {
    if (!files || files.length === 0) return;

    const file = files[0];
    const newImage = {
      id: Math.random().toString(36).substring(7),
      url: URL.createObjectURL(file),
      file,
      selected: false,
    };

    setUploadedImages((prev) => {
      const combined = [...prev, newImage];
      return combined.slice(0, 5); // Limit to 5 images
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => setIsDragging(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileSelect(e.dataTransfer.files);
  };

  const removeImage = (id) => {
    setUploadedImages((prev) => prev.filter((img) => img.id !== id));
  };

  const generateVideo = async () => {
    if (uploadedImages.length === 0) {
      alert("Please upload an image.");
      return;
    }

    setIsLoading(true);
    setTaskStatus("QUEUED");
    const model = "gen3a_turbo";

    const ratio = "768:1280";
    const prompt = textboxPrompt;
    const sourceImage = uploadedImages[0].file;

    try {
      const response = await postVideoGenerator(
        model,
        duration,
        ratio,
        prompt,
        sourceImage,
      );
      console.log("Video Generation Task ID:", response.task_id);
      pollTaskStatus(response.task_id);
    } catch (error) {
      console.error("Error generating video:", error);
      setIsLoading(false);
    }
  };
  const pollTaskStatus = async (task_id, maxRetry = 5, waitTime = 5000) => {
    console.log("Polling task status:", task_id, "maxRetry:", maxRetry);

    if (maxRetry === 0) {
      setIsLoading(false);
      throw new Error("max Retry for video generation has exceeded");
    }
    const interval = setInterval(async () => {
      try {
        const response = await getTaskStatusVideo(task_id);
        const { status, video_link } = response;
        console.log(response);

        if (status === "SUCCESS") {
          setTaskStatus("SUCCESS");
          setVideoLink(video_link);
          clearInterval(interval);
          setIsLoading(false);
        } else if (status === "QUEUED") {
          setTaskStatus("QUEUED");
          clearInterval(interval);
          pollTaskStatus(task_id, maxRetry - 1);
        } else if (status === "FAILED") {
          setTaskStatus("FAILED");
          clearInterval(interval);
          setIsLoading(false);
        } else {
          console.log("Current status:", status);
        }
      } catch (error) {
        console.error("Error checking task status:", error);
        clearInterval(interval);
        setIsLoading(false);
      }
    }, waitTime);
  };
  return (
    <div className="bg-black min-h-screen">
      <header className="py-12 mt-6 -mb-32 bg-black text-center">
        <h1 className="text-4xl md:text-4xl font-bold text-white">
          Image to Video Generator
        </h1>
        {/* <p className="text-lg text-gray-400 mt-2">Transform your images into stunning videos</p> */}
      </header>

      <div className="pt-24 container mx-auto p-6">
        <div className="grid md:grid-cols-2 gap-6">
          {/* Left Section */}
          <div className="space-y-6">
            {/* Upload Area */}
            <div
              className={`border-2 border-dashed  ${
                isDragging ? "border-blue-500 bg-blue-50/5" : "border-gray-600"
              } rounded-lg p-8 text-center transition-colors`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {uploadedImages.length > 0 ? (
                <div className="relative">
                  <img
                    src={uploadedImages[0].url}
                    alt="Uploaded preview"
                    className="w-full h-64 object-contain rounded-lg"
                  />
                </div>
              ) : (
                // Show upload instructions
                <div className="flex flex-col items-center justify-center">
                  <Image className="w-12 h-12 text-gray-400 mb-4" />
                  <p className="text-sm text-gray-300 mb-1">
                    Select a file or drag and drop here
                  </p>
                  <p className="text-xs text-gray-500 mb-4">
                    JPG, PNG, or WEBP files are supported
                  </p>
                  <p className="text-xs text-gray-500 mb-4">
                    Upload image limit maximum 5
                  </p>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    accept="image/jpeg,image/png,image/webp"
                    onChange={(e) => handleFileSelect(e.target.files)}
                  />
                  <button
                    className="px-4 py-2 bg-gray-800 text-white rounded hover:bg-gray-700 transition"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>

            {/* Prompt Section */}
            <div className="space-y-4">
              <div className="space-y-4">
                <div className="flex gap-2 items-center">
                  <button
                    className="px-4 py-2 bg-transparent text-gray-300 border border-gray-600 rounded"
                    onClick={() => setIsCustomPrompt(!isCustomPrompt)}
                  >
                    {isCustomPrompt ? "Custom Prompt" : "Custom Prompt"}
                  </button>
                  <div className="relative">
                    <button
                      className="px-4 py-2 bg-transparent text-gray-300 border border-gray-600 rounded flex items-center"
                      onClick={() =>
                        document
                          .getElementById("prompt-dropdown")
                          ?.classList.toggle("hidden")
                      }
                    >
                      {selectedPrompt || "Select a Prompt"}
                      <ChevronDown className="ml-2 h-4 w-4" />
                    </button>
                    <div
                      id="prompt-dropdown"
                      className="absolute z-10 w-full bg-gray-800 border border-gray-600 rounded mt-1 hidden"
                    >
                      {availablePrompts.map((prompt, index) => (
                        <button
                          key={index}
                          className="block w-full text-left text-white px-4 py-2 hover:bg-gray-700"
                          onClick={() => {
                            setTextboxPrompt(prompt);
                            setSelectedPrompt(prompt);
                          }}
                        >
                          {prompt}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-2 border border-gray-600 rounded p-2 w-2/5 focus-within:ring-2 focus-within:ring-gray-500">
                <label className="text-white font-normal">
                  Duration (seconds)
                </label>
                <select
                  className="w-full max-w-[80px] text-white bg-gray-800 text-center p-2 cursor-pointer rounded appearance-none focus:ring-gray-500 focus:bg-gray-800"
                  value={duration}
                  onChange={(e) => setDuration(parseInt(e.target.value))}
                >
                  <option value={5} className="bg-gray-800 text-white">
                    5
                  </option>
                  <option value={10} className="bg-gray-800 text-white">
                    10
                  </option>
                </select>
              </div>

              <textarea
                placeholder="Enter prompt"
                className="w-full bg-transparent text-gray-300 border border-gray-600 rounded p-2 min-h-[100px]"
                value={textboxPrompt}
                onChange={(e) => setTextboxPrompt(e.target.value)}
              />
              <button
                className="w-full px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition"
                onClick={generateVideo}
                disabled={isLoading}
              >
                {isLoading ? "Generating..." : "Generate Video"}
              </button>
            </div>
          </div>

          {/* Right Section */}
          <div className="space-y-6">
            {/* Video Preview */}
            <div className="aspect-video bg-gray-800 rounded-lg relative flex items-center justify-center">
              {videoLink ? (
                <video
                  src={videoLink}
                  controls
                  className="w-full h-full rounded-lg"
                >
                  Your browser does not support the video tag.
                </video>
              ) : (
                <div className="text-gray-500">
                  {isLoading ? "Generating video..." : "No video available."}
                </div>
              )}
            </div>

            {/* Timeline 
            <div className="bg-gray-800 h-12 rounded-lg relative">
              <div className="absolute left-4 top-1/2 h-1 w-[calc(100%-2rem)] bg-gray-600 -translate-y-1/2">
                <div className="absolute left-1/3 top-1/2 h-4 w-1 bg-orange-500 -translate-y-1/2" />
              </div>
            </div>*/}
            {/* Action Buttons */}
            <div className="flex justify-between items-center">
              <div className="flex gap-4">
                {/* <button className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition flex items-center">
                  <Merge className="w-4 h-4 mr-2" />
                  Merge Videos
                </button> */}
                <button
                  className="px-4 py-2 bg-gray-600 ml-60 text-white rounded hover:bg-gray-500 transition flex items-center"
                  disabled={!videoLink}
                  onClick={() => {
                    const downloadAnchor = document.createElement("a");
                    downloadAnchor.href = videoLink;
                    downloadAnchor.download = "generated-video.mp4";
                    document.body.appendChild(downloadAnchor);
                    downloadAnchor.click();
                    document.body.removeChild(downloadAnchor);
                  }}
                >
                  <Download className="w-4 h-4 mr-2" />
                  Download
                </button>

                {/* <button className="p-2 text-gray-300 hover:bg-gray-700 rounded transition">
                  <Info className="w-4 h-4" />
                </button> */}
              </div>
              {/* <button className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition">
                Save
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
