import React from "react";
import { useState } from "react";
import { Poses, Clothes } from "../ReuseComponents/images.js";
import { Plus } from "lucide-react";
import Image1 from "../../assets/Demo_Images/Demo_catalogue/model1.jpg";
import Image2 from "../../assets/Demo_Images/Demo_catalogue/Cloth1.jpg";
import Image3 from "../../assets/Demo_Images/Demo_catalogue/CatalogueModel1.jpg";
import GenratedCatalogue from "./GenratedCatalogue.jsx";
import FileUploader from "./FileUploader.jsx";
import { useSelector } from "react-redux";
const ImageProcesses = ({
  title,
  uploadTitle,
  onImageSelect,
  onImageUpload,
  onRun,
  selectedImage = {},
  selectedFilter: selectedFilterProp,
  isRunning,
  Header,
  type_run,
}) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("Upper Wear");
  const [showProgressBar, setShowProgressBar] = useState(false);

  const [showAllPoses, setShowAllPoses] = useState(false);
  const [showCatalogue, setShowCatalogue] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedGender, setSelectedGender] = useState("Male");
  const [isBackImageUploadEnabled, setIsBackImageUploadEnabled] =
    useState(false);
  const [selectedBackImage, setSelectedBackImage] = useState(null);
  const modelImage = Image1;
  const clothImage = Image2;

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  const catalogueImages = [
    {
      src: Image3,
      alt: "Filtered Image 1",
    },
    {
      src: Image3,
      alt: "Filtered Image 2",
    },
    {
      src: Image3,
      alt: "Filtered Image 3",
    },
    {
      src: Image3,
      alt: "Filtered Image 4",
    },
    {
      src: Image3,
      alt: "Filtered Image 5",
    },
    {
      src: Image3,
      alt: "Filtered Image 6",
    },
    {
      src: Image3,
      alt: "Filtered Image 7",
    },
    {
      src: Image3,
      alt: "Filtered Image 8",
    },
  ];

  const handleClosePoses = () => {
    setShowAllPoses(false);
  };
  const handleShowMoreClothes = () => {
    setShowAllPoses(true);
  };
  const [ModelImage, setModelImage] = useState({
    newImage: Poses[0]?.image || "",
    newImageUrl: Poses[0]?.image || "",
  });
  const [UploadedImage, setUploadedImage] = useState({
    newImage: Poses[0]?.image || "",
    newImageUrl: Poses[0]?.image || "",
  });

  const handleFilterSelect = (filter) => {
    if (!filter || !filter.image) {
      console.error("Invalid filter selected:", filter);
      return;
    }

    onImageSelect(filter.image);

    setUploadedImage({
      newImage: filter.image,
      newImageUrl: filter.image,
    });

    setShowAllImages(false);
  };
  const handleModelImageSelect = (img) => {
    setModelImage((prevState) => ({
      ...prevState,
      newImageUrl: img.image,
    }));
    setShowAllPoses(false);
  };
  const handleRun = () => {
    setShowProgressBar(true);
    setShowCatalogue(true);
    onRun();
    setTimeout(() => setShowProgressBar(false), 8000);
  };

  const handleShowMore = () => {
    setShowAllImages(true);
  };
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };
  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
  };

  const toggleBackImageUpload = () => {
    setIsBackImageUploadEnabled((prev) => !prev);
  };

  const onBackImageUpload = (image) => {
    setSelectedBackImage(image);
  };

  const handleModelReset = () => {
    setModelImage({
      newImage: Poses[0]?.image || "",
      newImageUrl: Poses[0]?.image || "",
    });
    setSelectedFilter("");
    setSelectedGender("Male");
    setFullscreenImage(null);
    setShowAllImages(false);
    setShowProgressBar(false);
    setCurrentImageIndex(0);
  };
  const handleClotheReset = () => {
    setSelectedFilter("Upper Wear");
  };

  return (
    <div
      className={` ${isSidebarOpen ? "ml-60" : ""} transition-all duration-200  mx-8 mt-16 p-4 `}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>

      <div className="flex flex-col lg:flex-row items-center">
        <div className="w-full rounded-lg">
          <div className="flex flex-col md:flex-row items-center justify-center gap-4 rounded-lg text-white">
            {/*This is First*/}
            <div className="w-full rounded-lg p-4">
              <h2 className="text-lg text-center mb-4 ">{uploadTitle}</h2>
              <div
                className={`flex flex-row max-w-[300px] mx-auto mb-4  ${isBackImageUploadEnabled ? "gap-4 " : ""}`}
              >
                {/* Front Image Upload */}
                <div
                  className={`flex-1 ${isBackImageUploadEnabled ? "-ml-40" : ""}`}
                >
                  {selectedImage?.blobImageUrl ? (
                    <img
                      src={selectedImage.blobImageUrl}
                      alt="preview"
                      className={`w-full h-full object-contain rounded-lg ${
                        selectedFilter === selectedImage.newImageUrl
                          ? "ring-4 ring-purple-600"
                          : ""
                      }`}
                    />
                  ) : (
                    <FileUploader handleImageUpload={onImageUpload} />
                  )}
                  <p className="text-center text-sm text-gray-400 mt-2">
                    Front Image
                  </p>
                </div>

                {/* Back Image Upload (conditionally rendered) */}
                {isBackImageUploadEnabled && (
                  <div className="flex-1">
                    {selectedBackImage?.blobImageUrl ? (
                      <img
                        src={selectedBackImage.blobImageUrl}
                        alt="back preview"
                        className="w-full h-full object-contain rounded-lg"
                      />
                    ) : (
                      <FileUploader handleImageUpload={onBackImageUpload} />
                    )}
                    <p className="text-center text-sm text-gray-400 mt-2">
                      Back Image
                    </p>
                  </div>
                )}
              </div>

              {/* Toggle Switch */}
              <div className="w-max ml-40 flex items-center gap-4">
                <label className="text-lg font-medium text-black">
                  Upload Back Image
                </label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={isBackImageUploadEnabled}
                    onChange={toggleBackImageUpload}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-green-500 peer-focus:ring-2 peer-focus:ring-green-300 transition-all"></div>
                  <span className="absolute top-0.5 left-0.5 bg-white w-5 h-5 rounded-full peer-checked:translate-x-5 transition-all"></span>
                </label>
              </div>

              <div className="mb-6 ml-40">
                <label className="block text-lg font-medium mb-2 ml-2 text-black">
                  Select Cloth Type
                </label>
                <div className="w-full flex gap-4 ml-4">
                  <label className="flex items-center w-max text-black mb-2">
                    <input
                      type="radio"
                      name="filter"
                      value="Upper Wear"
                      checked={selectedFilter === "Upper Wear"}
                      onChange={(e) => handleFilterChange(e.target.value)}
                      className="mr-2 w-4 h-4"
                    />
                    Upper Wear
                  </label>
                  <label className="flex w-max items-center mb-2 text-black">
                    <input
                      type="radio"
                      name="filter"
                      value="Bottom Wear"
                      checked={selectedFilter === "Bottom Wear"}
                      onChange={(e) => handleFilterChange(e.target.value)}
                      className="mr-2 w-4 h-4"
                    />
                    Bottom Wear
                  </label>
                  <label className="flex items-center text-black ">
                    <input
                      type="radio"
                      name="filter"
                      value="Dressed"
                      checked={selectedFilter === "Dressed"}
                      onChange={(e) => handleFilterChange(e.target.value)}
                      className="mr-2 w-4 h-4"
                    />
                    Dressed
                  </label>
                </div>
              </div>

              <div className="flex justify-center mb-4">
                <div className="flex items-center gap-2 overflow-x-auto">
                  {Clothes.slice(currentImageIndex, currentImageIndex + 3).map(
                    (img, index) => (
                      <img
                        key={index}
                        src={img.image}
                        className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                          selectedFilter === img.image
                            ? "border-2 border-yellow-400"
                            : "border-2 border-white"
                        }`}
                        alt={`Filter ${currentImageIndex + index + 1}`}
                        onClick={() => handleFilterSelect(img)}
                      />
                    ),
                  )}

                  <button
                    className="w-20 h-20 p-2 bg-gray-200 text-blue-900 text-sm rounded-lg hover:bg-gray-300 transition-colors flex items-center justify-center"
                    onClick={handleShowMore}
                  >
                    <span className="text-sm">Show More</span>
                  </button>
                </div>
              </div>

              {UploadedImage.newImageUrl && (
                <div className="flex justify-center">
                  <button
                    onClick={handleClotheReset}
                    className="bg-red-500 px-12 py-2 rounded-lg text-black uppercase"
                  >
                    Reset
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-center">
              <Plus className="text-gray-500 w-12 h-12 mb-2 absolute top-64" />
            </div>
            {/*This is second*/}
            <div className="w-full rounded-lg p-4 ">
              <h2 className="text-lg text-center mb-4 text-black">{title}</h2>

              <div className="aspect-square w-full max-w-[300px] mx-auto mb-4 border-4 border-yellow-600 rounded-xl">
                <img
                  src={ModelImage.newImageUrl}
                  alt="Selected Model"
                  className="w-full h-full object-contain rounded-lg"
                />
              </div>

              <div className="mb-6 ml-40">
                <label className="block text-lg font-medium mb-2 ml-2 text-black">
                  Select Gender
                </label>
                <div className="w-full ml-4 flex gap-4">
                  <label className="flex items-center text-black">
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      checked={selectedGender === "Male"}
                      onChange={(e) => handleGenderChange(e.target.value)}
                      className="mr-2 w-4 h-4"
                    />
                    Male
                  </label>
                  <label className="flex items-center text-black">
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      checked={selectedGender === "Female"}
                      onChange={(e) => handleGenderChange(e.target.value)}
                      className="mr-2 w-4 h-4"
                    />
                    Female
                  </label>
                </div>
              </div>

              {/* Poses for Selection */}
              <div className="flex justify-center mb-4">
                <div className="flex items-center gap-2 overflow-x-auto">
                  {Poses.slice(currentImageIndex, currentImageIndex + 3).map(
                    (img, index) => (
                      <img
                        key={index}
                        src={img.image}
                        className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                          UploadedImage.newImageUrl === img.image
                            ? "border-4 border-yellow-600"
                            : "border-2 border-white"
                        }`}
                        alt={`Pose ${currentImageIndex + index + 1}`}
                        onClick={() => handleModelImageSelect(img)}
                      />
                    ),
                  )}
                  <button
                    className="w-20 h-20 p-2 bg-gray-200 text-blue-900 text-sm rounded-lg hover:bg-gray-300 transition-colors flex items-center justify-center"
                    onClick={handleShowMoreClothes}
                  >
                    <span className="text-sm">Show More</span>
                  </button>
                </div>
              </div>

              {/* Reset Button */}
              {ModelImage.newImageUrl && (
                <div className="flex justify-center">
                  <button
                    onClick={handleModelReset}
                    className="bg-red-500 px-12 py-2 rounded-lg text-black uppercase"
                  >
                    Reset
                  </button>
                </div>
              )}
            </div>
            {/* Processed Image Section */}
            {/* 
                        <div className="flex items-center -ml-20">
                            <ArrowRight className="text-gray-500 w-12 h-12 absolute top-96" />
                        </div> */}
          </div>
        </div>

        {/* Fullscreen Image Modal */}
        {fullscreenImage && (
          <div
            onClick={setFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}
        {showCatalogue && (
          <div className="fixed -top-10 left-0 w-full h-full bg-white text-black flex items-center justify-center z-50">
            <GenratedCatalogue
              clothImage={clothImage}
              modelImage={modelImage}
              catalogueImages={catalogueImages}
              onBack={() => setShowCatalogue(false)}
            />
          </div>
        )}
        {showAllImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[100vh] relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-red-600 hover:text-gray-800"
                onClick={() => setShowAllImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6 text-black">
                Choose an Image
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 overflow-y-auto max-h-[80vh] p-2">
                {Clothes.map((img, index) => (
                  <img
                    key={index}
                    src={img.image}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedFilter === img
                        ? "ring-8 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Filter ${index + 1}`}
                    onClick={() => handleFilterSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Pop-up for all poses */}
        {showAllPoses && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg border-2 border-white p-8 w-11/12 max-w-5xl max-h-[100vh] relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-red-600 hover:text-gray-800"
                onClick={handleClosePoses}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6 text-black">
                Choose a Model
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 overflow-y-auto max-h-[80vh] p-2">
                {Poses.map((img, index) => (
                  <img
                    key={index}
                    src={img.image}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      UploadedImage.newImageUrl === img.image
                        ? "ring-8 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Pose ${index + 1}`}
                    onClick={() => handleModelImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Run Button */}
      <div className="flex items-center justify-center mt-4">
        <button
          className={`w-full uppercase max-w-[400px] px-4 py-2 rounded-lg flex items-center justify-center text-lg font-semibold ${
            selectedFilter && selectedImage.blobImage
              ? "bg-[#E4AA0E] text-black hover:bg-yellow-500"
              : "bg-gray-700 text-gray-500 cursor-not-allowed"
          } transition-colors`}
          onClick={handleRun}
          disabled={!selectedFilter || !selectedImage.blobImage || isRunning}
        >
          {isRunning ? "Processing..." : type_run}
        </button>
      </div>
      {/* {showProgressBar && (
                <div className="absolute inset-0 bg-black opacity-80 flex items-center justify-center z-10">
                    <CircularProgressBar duration={8} />
                </div>
            )} */}
    </div>
  );
};

export default ImageProcesses;
