import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import { toast } from "react-toastify";
import { confirmPasswordReset, login } from "../../api/authApi";
import logo from "../../assets/images/logo.svg";

const PasswordReset = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [resetting, setIsResetting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlToken = params.get("token");

    if (!urlToken) {
      toast.error(
        "No token found in URL. Please request a new password reset link.",
      );
      setIsLoading(false);
      return;
    }

    setToken(urlToken);
    setIsLoading(false);
  }, [location]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (password1 !== password2) {
      toast.error("Passwords do not match!");
      return;
    }

    if (password1.length < 8) {
      toast.error("Password should be at least 8 characters long");
      return;
    }

    try {
      setIsResetting(true);
      const response = await confirmPasswordReset(token, password1, password2);

      if (response.status === 200) {
        toast.success(
          "Password has been reset successfully. Logging you in...",
        );

        // Auto-login
        try {
          const loginResponse = await login({
            email: response.data.email,
            password: password1,
          });
          if (loginResponse.status === 200) {
            setTimeout(() => navigate("/dashboard"), 2000);
          } else {
            throw new Error("Login failed after password reset");
          }
        } catch (loginError) {
          console.error("Auto-login failed:", loginError);
          toast.info(
            "Password reset successful. Please log in with your new password.",
          );
          setTimeout(() => navigate("/"), 2000);
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error(
        error.response?.data?.error ||
          "Error resetting password. Please try again or request a new reset link.",
      );
    } finally {
      setIsResetting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#222226] flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#222226] flex flex-col items-center justify-center px-4">
      <div className="w-full max-w-md">
        {/* Logo Section */}
        <Link to={"/"} className="flex justify-center mb-8">
          <img src={logo} alt="Logo" className="h-20 w-auto" />
        </Link>

        {/* Password Reset Card */}
        <div className="bg-[#333537] rounded-xl shadow-lg p-8">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-semibold text-white mb-2">
              Reset Password
            </h2>
            <p className="text-gray-400">Enter your new password below</p>
          </div>

          <form onSubmit={handlePasswordReset} className="space-y-6">
            {/* New Password Field */}
            <div>
              <p className="block text-sm font-medium text-gray-200 mb-2">
                New Password
              </p>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                  placeholder="Enter new password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            {/* Confirm Password Field */}
            <div>
              <p className="block text-sm font-medium text-gray-200 mb-2">
                Confirm New Password
              </p>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                  placeholder="Confirm new password"
                  required
                />
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={resetting}
              className="w-full bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {resetting ? "Resetting password..." : "Reset Password"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
