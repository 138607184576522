import React, { useState } from "react";
import { Download } from "lucide-react";
import { saveAs } from "file-saver";
import Image1 from "../../assets/Demo_Images/Demo_catalogue/model1.jpg";
import Image2 from "../../assets/Demo_Images/Demo_catalogue/Cloth1.jpg";
import Image3 from "../../assets/Demo_Images/Demo_catalogue/CatalogueModel1.jpg";
import JSZip from "jszip";
import { useSelector } from "react-redux";
function GenratedCatalogue({
  clothImage,
  modelImage,
  catalogueImages = [],
  onBack,
}) {
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  const handlePreviewImageOpen = (imageUrl) => {
    setShowPreviewModal(true);
    setPreviewImage(imageUrl);
  };

  const handlePreviewCloseModal = () => {
    setShowPreviewModal(false);
  };

  const images = [Image1, Image2, Image3, Image3, Image3, Image3];

  const handleDownload = async () => {
    const zip = new JSZip();
    const folder = zip.folder("CatalogueDemo");

    if (!folder) {
      console.error("Failed to create folder in the zip archive.");
      return;
    }

    try {
      for (let i = 0; i < images.length; i++) {
        try {
          const response = await fetch(images[i]);

          if (!response.ok) {
            throw new Error(
              `Failed to fetch image ${i + 1}: ${response.statusText}`,
            );
          }

          const blob = await response.blob();
          folder.file(`image${i + 1}.jpg`, blob);
        } catch (error) {
          // console.error(`Error downloading image ${i + 1}:`, error);
        }
      }

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "CatalogueDemo.zip");
      // console.log("Download completed successfully.");
    } catch (error) {
      console.error("Error generating zip file:", error);
    }
  };

  return (
    <div
      className={` ${isSidebarOpen ? "ml-60" : ""} fixed left-0 right-0 bg-white overflow-hidden z-40 transition-all duration-200  mx-8 mt-40  border-2   p-4 shadow-md rounded-xl`}
    >
      <div className="p-6 h-[calc(108vh-4rem)] flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={onBack}
            className="px-4 py-2 bg-gray-700 text-white font-semibold rounded-lg hover:bg-gray-600 transition"
          >
            Back
          </button>
          <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">
            Generated Catalogue
          </h1>
          <div className="flex justify-center">
            <button
              onClick={handleDownload}
              className="flex flex-row items-center px-4 py-2 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-500 transition"
            >
              <Download className="mr-2" />
              <span>Download</span>
            </button>
          </div>
        </div>

        <div className="flex h-full space-x-4">
          <div className="w-1/5 h-5/6 space-y-6">
            {/* Cloth Image */}
            <div className="h-1/2 border-2 border-gray-700 rounded-lg p-4 bg-white flex flex-col items-center justify-center   backdrop-blur-md">
              <p className="text-lg font-semibold text-black mb-2">Cloth</p>
              <img
                src={clothImage || images[0]}
                alt="Cloth"
                className="max-h-full w-auto object-contain rounded-md border-1 border-white"
              />
            </div>

            {/* Model Image */}
            <div className="h-1/2 border-2 border-gray-700 rounded-lg p-4 bg-white flex flex-col items-center justify-center  backdrop-blur-md">
              <p className="text-lg font-semibold text-black mb-2">Model</p>
              <img
                src={modelImage || images[1]}
                alt="Model"
                className="max-h-full w-auto object-contain rounded-md border-1 border-white"
              />
            </div>
          </div>

          <div
            className="w-3/4"
            style={{
              maxHeight: "calc(100vh - 8rem)",
              overflowY: "scroll",
              scrollbarWidth: "thin",
              scrollbarColor: "gray transparent",
            }}
          >
            {/* Catalogue Grid */}
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 p-4 border-2 border-gray-700 rounded-lg bg-white">
              {catalogueImages.map((image, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center bg-black border border-white rounded-lg mb-4 shadow-md hover:scale-105 transition-transform duration-200  bg-white/10 backdrop-blur-3xl"
                >
                  <img
                    src={image.src || images[2]}
                    alt={image.alt || `Catalogue ${index}`}
                    className="max-w-full h-auto object-contain p-2 rounded-md"
                    onClick={() => handlePreviewImageOpen(image.src)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Image Preview */}
      {showPreviewModal && (
        <div className="fixed top-16 left-0 right-0 bottom-0 bg-black/40 backdrop-blur-md flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={previewImage}
              alt="Preview"
              className="h-3/4 w-3/4 object-contain rounded-lg shadow-lg"
            />
            <button
              onClick={handlePreviewCloseModal}
              className="absolute -top-6 right-36 mt-4 mr-4 w-10 h-10 flex items-center justify-center bg-white text-black p-2 rounded-full text-2xl hover:bg-red-800 hover:text-white transition-colors"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GenratedCatalogue;
