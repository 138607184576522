
import { React, useState } from "react";
import { AlignCenter, AlignEndHorizontal, AlignJustify, Image, Pencil, Sparkles } from "lucide-react";
import { DownloadButton } from "../Utils/DownloadButton.jsx";
import FileUploader from "./FileUploader.jsx";
import { useSelector } from "react-redux";
import FilterForm from "./FilterForm.jsx";
import PhotoLibrary from "./PhotoLibrary.jsx";

const ImageProcessor = ({
  title,
  images,
  modelImages,
  uploadTitle,
  onFaceSelect,
  onModelSelect,
  onFaceUpload,
  onModelUpload,
  onRun,
  selectedFace,
  selectedModel,
  outputImage,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
}) => {
  const [activeTab, setActiveTab] = useState("target");
  const [showLibrary, setShowLibrary] = useState({ target: true, source: true });
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [modelImage, setModelImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState({ target: false, source: false });
  const [toggleFilterShow, setToggleFilterShow] = useState(false)

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
  };

  const toggleLibrary = (type) => {
    setShowLibrary(prev => ({
      ...prev,
      [type]: !prev[type]
    }));
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleFaceReset = () => {
    setFaceImage({});
  };

  const handleModelReset = () => {
    setModelImage({});
  };

  const renderLoadingPlaceholders = () => {
    return Array(3)
      .fill(null)
      .map((_, index) => (
        <div
          key={index}
          className="w-16 h-16 animate-pulse bg-gray-700 rounded-lg"
        />
      ));
  };

  return (
    <div
      className={`${isSidebarOpen ? "ml-[200px]" : ""} bg-[#222226] transition-all duration-200 rounded-xl`}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>
      <div className="  flex flex-col lg:flex-row">

        <div className="flex flex-col px-2 ml-6 py-4 overflow-auto lg:w-[700px] h-screen">
          {/* Tabs */}
          <div className="flex  justify-between border-b border-gray-700">
            <button
              className={`px-6 py-3 text-base transition-colors ${
                activeTab === "target"
                  ? "text-white border-b-2 border-white"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("target")}
            >
              Source Image
            </button>
            <button
              className={`px-6 py-3 text-base transition-colors ${
                activeTab === "source"
                  ? "text-white border-b-2 border-white"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("source")}
            >
              Target Image
            </button>
          </div>

          <div className="flex gap-8">
            {activeTab === "target" && (
              <div className="w-full rounded-lg p-4">
                <div className="mb-4">
                  {faceImage.newImageUrl ? (
                    <div className="w-[200px] h-[200px] mx-auto mb-4">
                      <img
                        src={faceImage.newImageUrl}
                        alt="preview"
                        className="w-full h-full object-contain rounded-lg"
                      />
                    </div>
                  ) : (
                    <div className="mb-4">
                      <FileUploader handleImageUpload={handleFaceImageUpload} />
                      <p className="text-customGray text-xs text-center my-4">
                        Or select from photo library
                      </p>
                    </div>
                  )}
                </div>
                
                <div 
                  className="text-white flex justify-between items-center mb-4 cursor-pointer"
                  onClick={() => toggleLibrary('target')}
                >
                  <h1>Photo Library</h1>
                  <AlignJustify />
                </div>
                {showLibrary.target && (
                  <PhotoLibrary
                    images={images}
                    currentImageIndex={currentImageIndex}
                    selectedFace={selectedFace}
                    handleFaceImageSelect={handleFaceImageSelect}
                    renderLoadingPlaceholders={renderLoadingPlaceholders}
                  />
                )}
                {faceImage.newImageUrl && (
                  <div className="flex justify-center">
                    <button
                      onClick={handleFaceReset}
                      className="bg-[#333537] px-12 mt-2 py-2 rounded-lg text-white uppercase"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            )}

            {activeTab === "source" && (
              <div className="w-full rounded-lg p-4">
                <div className="mb-4">
                  {modelImage.newImageUrl ? (
                    <div className="w-[200px] h-[200px] mx-auto mb-4">
                      <img
                        src={modelImage.newImageUrl}
                        alt="preview"
                        className="w-full h-full object-contain rounded-lg"
                      />
                    </div>
                  ) : (
                    <div className="mb-4">
                      <FileUploader handleImageUpload={handleModelImageUpload} />
                      <p className="text-customGray text-xs text-center my-4">
                        Or select from photo library
                      </p>
                    </div>
                  )}
                </div>
                
                <div 
                  className="text-white flex justify-between items-center mb-4 cursor-pointer"
                  onClick={() => toggleLibrary('source')}
                >
                  <h1>Photo Library</h1>
                  <AlignJustify />
                </div>
                {showLibrary.source && (
                  <PhotoLibrary
                    images={modelImages}
                    currentImageIndex={currentImageIndex}
                    selectedFace={selectedModel}
                    handleFaceImageSelect={handleModelImageSelect}
                    renderLoadingPlaceholders={renderLoadingPlaceholders}
                  />
                )}
                {modelImage.newImageUrl && (
                  <div className="flex justify-center">
                    <button
                      onClick={handleModelReset}
                      className="bg-[#333537] px-12 mt-2 py-2 rounded-lg text-white uppercase"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          
          {faceImage.newImageUrl &&
            <div className="flex flex-col items-center  mb-4 justify-center">
              <div 
                  className="px-4 text-white w-full flex justify-between items-center mb-4 cursor-pointer"
                  onClick={() => setToggleFilterShow(!toggleFilterShow)}
                > 
                  <h1>Filters</h1>
                  <AlignJustify />
                </div>
              {toggleFilterShow && 
              <FilterForm />
              }
            </div>
          }
            <div className="">
              <button
                onClick={onRun}
                disabled={
                  !selectedFace.blobImage ||
                  !selectedModel.blobImage ||
                  isRunning
                }
                className="text-white w-full bg-[#333537] py-3 px-8 rounded-md cursor-pointer hover:bg-[#474a4d] transition-colors flex gap-2 items-center justify-center"
              >
                <span className="text-sm">
                  {isRunning ? "Generating..." : type_run}
                </span>
              </button>
            </div>
        </div>

        <div className="w-full min-h-screen bg-black">
          <div className="flex flex-co items-center justify-center md:flex-row gap-4">
            <div className="w-full md:w-1/2 relative rounded-lg py-4">
              <h2 className="text-lg text-white text-center mb-4">
                Output Image
              </h2>
              <div className="aspect-square w-full mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <h1 className="text-xl text-yellow-400">
                        Generation in Process
                      </h1>
                    </div>
                    <div className="loader"></div>
                  </div>
                ) : outputImage ? (
                  <img
                    src={outputImage}
                    alt="filtered"
                    className="w-full h-full object-contain rounded-lg cursor-pointer"
                    onClick={() => openFullscreenImage(outputImage)}
                  />
                ) : (
                  <div className="bg-[#333537] p-4 h-full flex flex-col items-center justify-center rounded-lg">
                    <Image size={200} className="text-gray-300" />
                    <h1 className="text-sm text-gray-500">
                      Nothing to see here yet!
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-[600px] ">
          <div className="mt-4 px-8 pb-4 flex flex-col justify-center gap-2 w-full">
            
            <div>
              <DownloadButton imageUrl={outputImage} />
            </div>
          </div>
          <div className=" flex text-white items-center justify-around px-8 py-4 border-b border-t border-[#414141] ">
            <h1 className="text-base">SKU ID: 1234</h1>
            <Pencil size={16}/>  
          </div>
        </div>

        {/* Fullscreen Modal */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageProcessor;