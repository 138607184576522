export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
};

export const generateUniqueFilename = (extension) => {
  return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}.${extension}`;
};

// utils/constants.js
import { Image, Square, Smartphone, Monitor } from "lucide-react";

export const resizeOptions = [
  {
    icon: Image,
    label: "Original",
    size: { width: 450, height: 600 },
  },
  {
    icon: Square,
    label: "Square",
    size: { width: 400, height: 400 },
  },
  {
    icon: Smartphone,
    label: "Portrait",
    size: { width: 400, height: 500 },
  },
  {
    icon: Monitor,
    label: "Landscape",
    size: { width: 500, height: 400 },
  },
];

// You can add more image processing related constants here
export const IMAGE_QUALITY_OPTIONS = {
  HIGH: { label: "High Quality", value: 1 },
  MEDIUM: { label: "Medium Quality", value: 0.8 },
  LOW: { label: "Low Quality", value: 0.6 },
};

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const ACCEPTED_FILE_TYPES = ["image/jpeg", "image/png", "image/webp"];
