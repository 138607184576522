import { React, useRef, useState } from "react";
import {
  Image,
  Sparkles,
  ChevronLeft,
  ChevronRight,
  LockKeyhole,
  Square,
  Smartphone,
  Monitor,
} from "lucide-react";
import { DownloadButton } from "../../Utils/DownloadButton.jsx";
import FileUploader from "../FileUploader.jsx";
import EditableImage from "./EditImage.jsx";
import { useSelector } from "react-redux";
import Crown from "../../../assets/Crown.svg";

const ImageProcessor = ({
  title,
  images,
  modelImages,
  uploadTitle,
  onFaceSelect,
  onModelSelect,
  onFaceUpload,
  onModelUpload,
  onRun,
  selectedFace,
  selectedModel,
  appliedFilter,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
}) => {
  const [activeTab, setActiveTab] = useState("target");
  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllModelImages, setShowAllModelImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [isResizeOpen, setResizeOpen] = useState(false);

  const resizeOptions = [
    { icon: Image, label: "Original", size: { width: 450, height: 600 } },
    { icon: Square, label: "Square", size: { width: 400, height: 400 } },
    { icon: Smartphone, label: "Portrait", size: { width: 400, height: 500 } },
    { icon: Monitor, label: "Landscape", size: { width: 500, height: 400 } },
  ];

  const [selectedSize, setSelectedSize] = useState(resizeOptions[0].size);
  const [modelImage, setModelImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentModelImageIndex, setCurrentModelImageIndex] = useState(0);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const [isDownloadVisible, setIsDownloadVisible] = useState(true);
  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);
  const stageRef = useRef();
  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllImages(false);
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllModelImages(false);
  };

  const handleShowMoreFaces = () => {
    setShowAllImages(true);
  };

  const handleShowMoreModels = () => {
    setShowAllModelImages(true);
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleFaceReset = () => {
    setFaceImage({});
  };

  const handleModelReset = () => {
    setModelImage({});
  };

  return (
    <div
      className={`${isSidebarOpen ? "ml-52" : ""} bg-[#222226] transition-all duration-200  rounded-xl`}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>
      <div className="flex flex-col lg:flex-row">
        {/* {isSectionVisible && ( */}
        <div className="flex flex-col px-2 ml-6 py-4 overflow-auto  w-[740px] h-screen">
          {/* Tabs */}
          <div className="flex justify-between border-b border-gray-700">
            <button
              className={`px-6 py-3 text-base transition-colors ${
                activeTab === "target "
                  ? "text-white border-b-2 border-white"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("target")}
            >
              {title}
            </button>
            <button
              className={`px-6 py-3 text-base transition-colors ${
                activeTab === "source"
                  ? "text-white border-b-2 border-white"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("source")}
            >
              {uploadTitle}
            </button>
          </div>
          {/* Tab content */}
          <div className="flex gap-8">
            {activeTab === "target" && (
              <div className="w-full rounded-lg p-4">
                <div className=" w-[200px] h-[200px] mb-4 mx-auto">
                  {faceImage.newImageUrl ? (
                    <img
                      src={faceImage.newImageUrl}
                      alt="preview"
                      className="w-full h-full object-contain rounded-lg"
                    />
                  ) : (
                    <FileUploader handleImageUpload={handleFaceImageUpload} />
                  )}
                </div>
                <div className="flex  mb-2 overflow-auto">
                  <div className="flex items-center gap-2  ">
                    {images
                      .slice(currentImageIndex, currentImageIndex + 3)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img}
                          className={`w-16 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                            selectedFace === img
                              ? "border-2 border-yellow-400"
                              : ""
                          }`}
                          alt={`Filter ${currentImageIndex + index + 1}`}
                          onClick={() => handleFaceImageSelect(img)}
                        />
                      ))}
                    <button
                      className="w-16 h-16 shrink-0 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                      onClick={handleShowMoreFaces}
                    >
                      <span className="text-sm">Show More</span>
                    </button>
                  </div>
                </div>
                {faceImage.newImageUrl && (
                  <div className="flex justify-center">
                    <button
                      onClick={handleFaceReset}
                      className="bg-[#333537] px-12 mt-2 py-2 rounded-lg text-white uppercase"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            )}

            {activeTab === "source" && (
              <div className="w-full rounded-lg p-4">
                <div className="w-[200px] h-[200px] mb-4 mx-auto">
                  {modelImage.newImageUrl ? (
                    <img
                      src={modelImage.newImageUrl}
                      alt="preview"
                      className="w-full h-full object-contain rounded-lg"
                    />
                  ) : (
                    <FileUploader handleImageUpload={handleModelImageUpload} />
                  )}
                </div>
                <div className="flex justify-center mb-2">
                  <div className="flex items-center gap-2">
                    {modelImages
                      .slice(currentModelImageIndex, currentModelImageIndex + 3)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img}
                          className={`w-16 h-16 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                            selectedModel === img
                              ? "border-2 border-yellow-400"
                              : ""
                          }`}
                          alt={`Model ${currentModelImageIndex + index + 1}`}
                          onClick={() => handleModelImageSelect(img)}
                        />
                      ))}
                    <button
                      className="w-16 h-16 shrink-0 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                      onClick={handleShowMoreModels}
                    >
                      <span className="text-sm">Show More</span>
                    </button>
                  </div>
                </div>
                {modelImage.newImageUrl && (
                  <div className="flex justify-center">
                    <button
                      onClick={handleModelReset}
                      className="bg-[#333537] px-12 mt-2 py-2 rounded-lg text-white uppercase"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-6">
            <h3 className="text-lg text-gray-300 mb-4">Additional Options</h3>
            <ul className="-ml-4 flex flex-col gap-y-4 text-white">
              <li>
                <a>Background Image</a>
              </li>
              <li>
                <a>Add shadow</a>
              </li>
              <li className="flex flex-row">
                <a>Generate Background</a>{" "}
                <img src={Crown} className="ml-1 w-4 h-4" />
              </li>
              <li>
                <a>Blur Background</a>
              </li>
              <li className="relative">
                <a
                  className="cursor-pointer text-white  hover:text-gray-400"
                  onClick={() => setResizeOpen(!isResizeOpen)}
                >
                  Resize
                </a>
                {isResizeOpen && (
                  <div className="absolute mt-2 bg-[#333537] text-white -top-48 shadow-lg rounded-lg p-4 -ml-2 w-80 z-10">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-lg font-semibold">Resize</h3>
                      <button
                        className="text-gray-400 hover:text-gray-200 -mt-6"
                        onClick={() => setResizeOpen(false)}
                      >
                        ✕
                      </button>
                    </div>
                    <div className="flex flex-row -mt-3 ml-24 justify-between">
                      <div className="flex flex-col items-center mb-4 text-sm">
                        <div>Width</div>
                        <button className="bg-gray-200 text-black w-12 h-4 flex justify-center items-center rounded">
                          <input
                            type="number"
                            disabled
                            value={200}
                            className="bg-gray-200 text-black w-16 text-center rounded"
                          />
                        </button>
                      </div>
                      <div className="flex flex-col  items-center mb-4 text-sm">
                        <div>Height</div>
                        <button className="bg-gray-200 text-black w-12 h-4 flex justify-center items-center rounded">
                          <input
                            type="number"
                            disabled
                            value={200}
                            className="bg-gray-200 text-black w-16 text-center rounded"
                          />
                        </button>
                      </div>
                      <div className="flex flex-col justify-between items-center mb-4 text-sm">
                        <div>Ratio</div>
                        <button className="bg-gray-200 text-black w-8 h-8 flex justify-center items-center rounded">
                          <LockKeyhole className="w-4 h-4 text-black" />
                        </button>
                      </div>
                    </div>
                    <div className="w-72 h-[2px] bg-white mb-3 -ml-2"></div>
                    <ul className="space-y-2 w-full sm:w-[330px] px-4 -ml-8">
                      {resizeOptions.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => setSelectedSize(option.size)}
                          className="flex items-center p-1 bg-gray-700 rounded hover:bg-gray-600 cursor-pointer transition duration-200 ease-in-out"
                        >
                          <option.icon className="w-8 h-8 mr-4 text-gray-300 flex-shrink-0" />

                          {/* Text Content */}
                          <div className="flex flex-row flex-grow">
                            <span className="text-white font-medium">
                              {option.label}
                            </span>
                            <span className="text-sm text-gray-400 ml-14">
                              {`${option.size.width}×${option.size.height}`}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
        {/* // )} */}
        {/* <div className="absolute flex justify-center gap-4 mb-4 mt-80">
                    {!isSectionVisible && (
                        <button
                            onClick={() => setIsSectionVisible(true)}
                            className="bg-[#222226] px-3 py-2 h-max rounded text-white"
                        >
                            <ChevronRight />
                        </button>
                    )}
                    {isSectionVisible && (
                        <div className="left-[360px]  absolute">
                            <button
                                onClick={() => setIsSectionVisible(false)}
                                className="bg-[#222226] h-max px-3 py-2 rounded text-white"
                            >
                                <ChevronLeft className="ml-2" />
                            </button>
                        </div>
                    )}
                </div> */}
        <div className="w-full bg-black">
          <div className="flex flex-col items-center justify-center md:flex-row gap-4">
            <div className="w-full md:w-1/2 relative rounded-lg py-4">
              <h2 className="text-lg text-white text-center mb-4">
                Output Image
              </h2>
              <div className="w-full mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex  flex-col items-center justify-center">
                      <Image size={200} className="text-gray-300" />
                      <h1 className="text-xl text-yellow-400">
                        Generation in Process
                      </h1>
                    </div>
                    <div className="loader"></div>
                  </div>
                ) : appliedFilter ? (
                  <>
                    <div className="flex-grow w-full md:w-[70%]">
                      <EditableImage
                        containerWidth={selectedSize.width}
                        containerHeight={selectedSize.height}
                        backgroundSrc={faceImage}
                        modelSrc={appliedFilter}
                        setStageRef={(ref) => (stageRef.current = ref)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="bg-[#333537] p-4 h-full flex flex-col items-center justify-center rounded-lg">
                    <Image size={200} className="text-gray-300" />
                    <h1 className="text-sm text-gray-500">
                      Nothing to see here yet!
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className=" flex justify-center gap-4  mb-4 mt-80">
                    {isDownloadVisible && (
                        <div className="absolute left-[78%] ">
                            <button
                                onClick={() => setIsDownloadVisible(false)}
                                className="bg-[#222226] px-3 py-2 rounded text-white"
                            >
                                <ChevronLeft />
                            </button>
                        </div>
                    )}
                    {!isDownloadVisible && (
                        <div className="absolute left-[97%]">
                            <button
                                onClick={() => setIsDownloadVisible(true)}
                                className="absolute bg-[#222226] px-3 py-2 rounded text-white"
                            >
                                <ChevronRight />
                            </button>
                        </div>
                    )}
                </div> */}

        {/* {isDownloadVisible && ( */}
        <div className="w-[600px] mt-8">
          <div className="mt-8 px-8 flex border-b flex-col justify-center gap-2 w-full">
            <div className=" w-full">
              <button
                onClick={onRun}
                disabled={
                  !selectedFace.blobImage ||
                  !selectedModel.blobImage ||
                  isRunning
                }
                className="text-white w-full bg-[#333537] py-3 px-8 rounded-md cursor-pointer hover:bg-[#474a4d] transition-colors flex gap-2 items-center justify-center"
              >
                <Sparkles size={16} />
                <span className="text-sm">
                  {isRunning ? "Generating..." : "Generate"}
                </span>
              </button>
              <p className="text-gray-500 text-sm mt-2">Uses 2 Credit</p>
            </div>
            <div>
              <DownloadButton imageUrl={appliedFilter} />
              <p className="text-gray-500 text-sm mt-2">Uses 1 Credit</p>
            </div>
          </div>
          <div className="px-8 mt-8">
            <h1 className="text-sm text-white">SKU ID:</h1>
            <div className="border w-full px-2 py-2 mt-3 rounded-sm">
              <input
                type="text"
                placeholder="Enter your ID number"
                className="bg-transparent placeholder:text-sm h-full text-sm w-full text-white"
              />
            </div>
            <button className=" bg-gradient-to-r from-[#5345E6] to-[#613AB1] mt-3 text-white px-8 py-1 rounded-sm">
              Save
            </button>
          </div>
        </div>
        {/* )} */}
        {/* Your existing modals remain the same */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}

        {showAllImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-[#222226] rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllImages(false)}
              >
                ×
              </button>
              <h3 className="text-xl text-white mb-6">
                Choose a Background Image
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedFace === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Filter ${index + 1}`}
                    onClick={() => handleFaceImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {showAllModelImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllModelImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6">Choose a Model Image</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {modelImages.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedModel === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Model ${index + 1}`}
                    onClick={() => handleModelImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageProcessor;
