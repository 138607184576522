import React, { useState } from "react";
import ImageProcesses from "./ImageProcesses";

function GenerateCatalogue() {
  const [modelImages, setModelImages] = useState([]);
  const [clothImage, setClothImage] = useState("");
  const [selectedModelImage, setSelectedModelImage] = useState(null);
  const [outputImage, setOutputImage] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [isDownloadReady, setIsDownloadReady] = useState(false);

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const uploadedFile = event.target.files[0];
      setClothImage({
        blobImage: uploadedFile,
        blobImageUrl: URL.createObjectURL(uploadedFile),
      });
      setSelectedModelImage(null);
      setOutputImage(null);
      setIsDownloadReady(false);
    }
  };

  const handleClothingSelect = (image) => {
    setSelectedModelImage(image);
  };

  const handleRun = () => {
    if (isRunning) return;
    setIsRunning(true);

    setTimeout(() => {
      if (clothImage && selectedModelImage) {
        setOutputImage("processed_image_url");
        setIsDownloadReady(true);
      }
      setIsRunning(false);
    }, 3000);
  };

  return (
    <div>
      <ImageProcesses
        Header=""
        title="Models"
        images={modelImages}
        uploadTitle="Cloth"
        onImageSelect={handleClothingSelect}
        onImageUpload={handleImageUpload}
        onRun={handleRun}
        selectedImage={clothImage}
        selectedFilter={selectedModelImage}
        appliedFilter={outputImage}
        isRunning={isRunning}
        type_run="Generate Catalogue"
        isDownloadReady={isDownloadReady}
      />
    </div>
  );
}

export default GenerateCatalogue;
