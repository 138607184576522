import React, { useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowRight, Camera, Loader2 } from "lucide-react";
import { generateGarmentDetails } from "../../../api/modelsApi";
import GarmentDetailsDisplay from "./GarmentsDetailsDisplay";
import { updateTabState } from "../../../redux/studioSlice";
import FileUploader from "../../ImageProcessing/FileUploader"; // Import the new FileUploader component
import garment from "../../../assets/garment.png";
import { Sparkles } from "lucide-react";

const DescriptionGenerator = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.DESC_GEN);

  const [file, setFile] = useState(null);
  const [garmentDetails, setGarmentDetails] = useState(
    state?.garmentDetails || null,
  );
  const [error, setError] = useState(state?.error || null);
  const [isLoading, setIsLoading] = useState(state?.isLoading || false);
  const [previewUrl, setPreviewUrl] = useState(state?.previewUrl || null);

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  useEffect(() => {
    // Update Redux state when local state changes
    dispatch(
      updateTabState({
        tabId: "DESC_GEN",
        newState: {
          file: null,
          garmentDetails,
          error,
          isLoading,
          previewUrl,
        },
      }),
    );
  }, [garmentDetails, error, isLoading, previewUrl, dispatch]);

  const handleFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFile(null);
      setPreviewUrl(null);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setError("Please select an image file");
      return;
    }

    // Verify file properties before upload
    console.log("File details:", {
      name: file.name,
      type: file.type,
      size: file.size,
    });

    const formData = new FormData();
    formData.append("image", file, file.name);

    setIsLoading(true);
    setError(null);
    setGarmentDetails(null);

    try {
      const details = await generateGarmentDetails(file);
      setGarmentDetails(details);
    } catch (error) {
      console.error("Full API Error:", error);
      setError(error.message || "Failed to analyze garment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`  ${isSidebarOpen ? "ml-60" : ""} transition-all duration-200 flex mt-16 flex-col  mx-8 p-4`}
    >
      <h1 className="text-3xl mb-4 text-center text-[#1D3A72]"></h1>
      <div className="flex justify-between text-white items-center">
        <div className="w-full lg:w-[30%]">
          {/* upload */}
          <h2 className="text-lg text-center mb-4">Model Image</h2>
          <div className={` rounded-lg ${previewUrl ? "" : ""} p-4 mb-4`}>
            {previewUrl ? (
              <div className="mt-4 flex justify-center">
                <img
                  src={previewUrl}
                  alt="preview"
                  className="max-h-64 object-contain"
                />
              </div>
            ) : (
              <div>
                <FileUploader
                  handleImageUpload={(event) => {
                    handleFileChange(event);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <ArrowRight className="text-gray-500 w-12 h-12 mb-2 mr-6" />
        <div className="w-full lg:w-[60%] overflow-scroll">
          <h2 className="text-lg text-center mb-4">Garment Analysis</h2>
          <div className=" rounded-lg h-96">
            {isLoading ? (
              <div className=" rounded-lg p-6 flex items-center justify-center">
                <Loader2 className="mr-2 h-12 w-12 animate-spin text-purple-600" />
              </div>
            ) : garmentDetails ? (
              <GarmentDetailsDisplay garmentDetails={garmentDetails} />
            ) : (
              <div className=" rounded-lg flex border flex-col gap-4 items-center justify-center h-full">
                <img src={garment} alt="" className="h-32" />
                <p>Generated description will appear here</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-12">
        <button
          onClick={handleSubmit}
          disabled={!file || isLoading}
          className="  text-black bg-[#8AB4F8] py-3 px-8 rounded-md cursor-pointer hover:bg-[#5286d8] transition-colors flex gap-2 items-center justify-center"
        >
          <Sparkles size={16} />
          <span className="text-sm">
            {" "}
            {isLoading ? "Generating..." : "Generate"}
          </span>
        </button>
        {/* Run Button */}
      </div>
      {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
    </div>
  );
};

export default DescriptionGenerator;
