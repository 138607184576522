import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFullModelImages, getClothImages } from "../../../api/statics.jsx";
import { postApparelSwap, pollForTaskFinish } from "../../../api/modelsApi.jsx";
import { taskStatus } from "../../../config/constants.js";
import ImageProcessor from "../ImageProcessor.jsx";
import { AlertMessage } from "../../Utils/AlertMessage.jsx";
import { updateTabState } from "../../../redux/studioSlice";

const ApparelSwap = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.APPAREL_SWAP);

  const [selectedModel, setSelectedModel] = useState({
    blobImage: null,
    blobImageUrl: state?.selectedModel?.blobImageUrl || "",
  });

  const [selectedCloth, setSelectedCloth] = useState({
    blobImage: null,
    blobImageUrl: state?.selectedCloth?.blobImageUrl || "",
  });

  const [appliedFilter, setAppliedFilter] = useState(
    state?.appliedFilter || null,
  );
  const [warningMessage, setWarningMessage] = useState(
    state?.warningMessage || "",
  );
  const [modelImages, setModelImages] = useState(state?.modelImages || []);
  const [clothImages, setClothImages] = useState(state?.clothImages || []);
  const [isDownloadReady, setIsDownloadReady] = useState(
    state?.isDownloadReady || false,
  );
  const [alertVisible, setAlertVisible] = useState(
    state?.alertVisible || false,
  );
  const [isRunning, setIsRunning] = useState(state?.isRunning || false);

  useEffect(() => {
    const fetchImages = async () => {
      if (modelImages.length === 0 || clothImages.length === 0) {
        try {
          const [modelImageData, clothImageData] = await Promise.all([
            getFullModelImages(),
            getClothImages(),
          ]);

          const modelImageUrls = modelImageData.map((image) => image.file);
          const clothImageUrls = clothImageData.map((image) => image.file);

          setModelImages(modelImageUrls);
          setClothImages(clothImageUrls);

          dispatch(
            updateTabState({
              tabId: "APPAREL_SWAP",
              newState: {
                modelImages: modelImageUrls,
                clothImages: clothImageUrls,
              },
            }),
          );
        } catch (error) {
          console.error("Error fetching images:", error);
          setWarningMessage("Failed to load images.");
          setAlertVisible(true);
        }
      }
    };
    fetchImages();
  }, [modelImages.length, clothImages.length, dispatch]);

  const handleModelSelect = (model, blobImage = null) => {
    setSelectedModel({
      blobImage: blobImage || model,
      blobImageUrl: model,
    });
    dispatch(
      updateTabState({
        tabId: "APPAREL_SWAP",
        newState: {
          selectedModel: {
            blobImageUrl: model,
          },
        },
      }),
    );
  };

  const handleClothSelect = (cloth, blobImage = null) => {
    setSelectedCloth({
      blobImage: blobImage || cloth,
      blobImageUrl: cloth,
    });
    dispatch(
      updateTabState({
        tabId: "APPAREL_SWAP",
        newState: {
          selectedCloth: {
            blobImageUrl: cloth,
          },
        },
      }),
    );
  };

  const handleModelUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      const blobUrl = URL.createObjectURL(blobImage);
      handleModelSelect(blobUrl, blobImage);
      setAppliedFilter(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
    }
  };

  const handleClothUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const blobImage = event.target.files[0];
      const blobUrl = URL.createObjectURL(blobImage);
      handleClothSelect(blobUrl, blobImage);
      setAppliedFilter(null);
      setIsDownloadReady(false);
      setWarningMessage("");
      setAlertVisible(false);
    }
  };

  const handleRun = async () => {
    if (isRunning) {
      setWarningMessage("Task already in progress.");
      setAlertVisible(true);
      return;
    }

    if (!selectedCloth.blobImage || !selectedModel.blobImage) {
      setWarningMessage("Please select both cloth and model images.");
      setAlertVisible(true);
      return;
    }

    setIsRunning(true);
    try {
      const response = await postApparelSwap(
        selectedCloth.blobImage,
        selectedModel.blobImageUrl,
      );
      const taskId = response.task_id;
      const statusObj = await pollForTaskFinish(taskId);

      if (statusObj.status === taskStatus.SUCCESS) {
        setAppliedFilter(statusObj.output);
        setIsDownloadReady(true);
        dispatch(
          updateTabState({
            tabId: "APPAREL_SWAP",
            newState: {
              appliedFilter: statusObj.output,
              isDownloadReady: true,
            },
          }),
        );
      } else if (
        statusObj.status === taskStatus.FAILED ||
        statusObj.status === taskStatus.EMPTY_OUTPUT
      ) {
        throw new Error("Failed to process image!");
      } else {
        throw new Error(`Unknown task status: ${statusObj.status}`);
      }
    } catch (error) {
      setWarningMessage(error.message);
      setAlertVisible(true);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <>
      <ImageProcessor
        Header=""
        title="Model Image"
        images={modelImages}
        modelImages={clothImages}
        uploadTitle="Apparel Image"
        onFaceSelect={handleModelSelect}
        onModelSelect={handleClothSelect}
        onFaceUpload={handleModelUpload}
        onModelUpload={handleClothUpload}
        onRun={handleRun}
        selectedFace={selectedModel}
        selectedModel={selectedCloth}
        outputImage={appliedFilter}
        isRunning={isRunning}
        isDownloadReady={isDownloadReady}
        type_run="Swap Apparel"
      />
      {alertVisible && warningMessage && (
        <AlertMessage
          message={warningMessage}
          onClose={() => {
            setAlertVisible(false);
            dispatch(
              updateTabState({
                tabId: "APPAREL_SWAP",
                newState: { alertVisible: false },
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default ApparelSwap;
