import { React, useState, useRef, useEffect } from "react";
import { DownloadButton } from "../../Utils/DownloadButton.jsx";
import DemoImage from "../../../assets/images/demoImage.svg";
import FileUploader from "../FileUploader.jsx";
import { useSelector } from "react-redux";
import { generateUniqueFilename } from "../../../utils/utils";
import { Stage, Layer, Image, Transformer } from "react-konva";
import Crown from "../../../assets/Crown.svg";

import {
  Sparkles,
  ChevronLeft,
  ChevronRight,
  LockKeyhole,
  Square,
  Smartphone,
  Monitor,
} from "lucide-react";

const EditableImage = ({
  backgroundSrc,
  modelSrc,
  setStageRef,
  containerWidth = 300,
  containerHeight = 380,
}) => {
  console.log(backgroundSrc, modelSrc);
  const [isSelected, setIsSelected] = useState(false);
  const [showShadowDropdown, setShowShadowDropdown] = useState(false);
  const [containerSize, setContainerSize] = useState({
    width: containerWidth,
    height: containerHeight,
  });
  const imageRef = useRef(null);
  const trRef = useRef(null);
  const stageRef = useRef(null);
  const [activeTab, setActiveTab] = useState("shadowOptions");
  const [isDraggable, setIsDraggable] = useState(false);
  const [lightDirection, setLightDirection] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [modelImage, setModelImage] = useState(null);
  const [isBackgroundSelected, setIsBackgroundSelected] = useState(false);
  const [glowEnabled, setGlowEnabled] = useState(true);
  const backgroundTrRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("#0e3b4b");
  const [shadowProps, setShadowProps] = useState({
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowBlur: 5,
    shadowOpacity: 0.6,
    shadowOffsetX: 28,
    shadowOffsetY: -2,
  });

  const handleTabChange = (tab = "dropShadow") => {
    setActiveTab(tab);

    if (tab === "dropShadow") {
      setShadowProps({
        shadowColor: "rgba(0, 0, 0, 0.5)",
        shadowBlur: 5,
        shadowOpacity: 0.6,
        shadowOffsetX: 28,
        shadowOffsetY: -2,
      });
    } else if (tab === "aiAngle") {
      setShadowProps({
        shadowColor: "rgba(0, 0, 0, 0)",
        shadowBlur: 0,
        shadowOpacity: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      });
    }
  };
  const loadImage = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.crossOrigin = "anonymous";
      img.src = src;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  useEffect(() => {
    if (backgroundSrc?.newImageUrl) {
      loadImage(backgroundSrc.newImageUrl)
        .then((img) => {
          setBackgroundImage(img);
        })
        .catch((err) => console.error(err));
    }
    if (modelSrc) {
      loadImage(modelSrc)
        .then(setModelImage)
        .catch((err) => console.error(err));
    }

    if (setStageRef) {
      setStageRef(stageRef.current);
    }
  }, [backgroundSrc, modelSrc, setStageRef]);
  useEffect(() => {
    if (backgroundRef.current && backgroundTrRef.current) {
      if (isBackgroundSelected) {
        backgroundTrRef.current.nodes([backgroundRef.current]);
      } else {
        backgroundTrRef.current.nodes([]);
      }
      backgroundTrRef.current.getLayer().batchDraw();
    }
  }, [isBackgroundSelected]);

  useEffect(() => {
    if (imageRef.current && trRef.current) {
      if (isSelected) {
        trRef.current.nodes([imageRef.current]);
      } else {
        trRef.current.nodes([]);
      }
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  useEffect(() => {
    setContainerSize({
      width: containerWidth,
      height: containerHeight,
    });
  }, [containerWidth, containerHeight]);

  const handleDeselect = (e) => {
    if (e.target === stageRef.current) {
      setIsSelected(false);
    }
  };

  const handleDownload = () => {
    if (!stageRef.current) {
      console.error("Stage reference is not available for download.");
      return;
    }
    setIsSelected(false);
    setTimeout(() => {
      const dataURL = stageRef.current.toDataURL({
        pixelRatio: 2,
      });
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = generateUniqueFilename(".png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 1000);
  };
  const backgroundRef = useRef();
  const [backgroundProps, setBackgroundProps] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });

  const handleDoubleClick = () => {
    setIsDraggable(!isDraggable);

    if (!backgroundImage) return;

    const containerRatio = containerSize.width / containerSize.height;
    const imageRatio = backgroundImage.width / backgroundImage.height;

    let scale;
    if (containerRatio > imageRatio) {
      scale = containerSize.width / backgroundImage.width;
    } else {
      scale = containerSize.height / backgroundImage.height;
    }

    setBackgroundProps({
      x: 0,
      y: 0,
      scale,
    });
  };

  useEffect(() => {
    if (backgroundImage) {
      const containerRatio = containerSize.width / containerSize.height;
      const imageRatio = backgroundImage.width / backgroundImage.height;

      let scale;
      if (containerRatio > imageRatio) {
        scale = containerSize.width / backgroundImage.width;
      } else {
        scale = containerSize.height / backgroundImage.height;
      }

      setBackgroundProps({
        x: (containerSize.width - backgroundImage.width * scale) / 2,
        y: (containerSize.height - backgroundImage.height * scale) / 2,
        scale,
      });
    }
  }, [backgroundImage, containerSize]);

  const handleDragMove = (e) => {
    const { x, y } = e.target.position();
    const scaledWidth = backgroundImage.width * backgroundProps.scale;
    const scaledHeight = backgroundImage.height * backgroundProps.scale;

    const newX = Math.max(Math.min(x, 0), containerSize.width - scaledWidth);
    const newY = Math.max(Math.min(y, 0), containerSize.height - scaledHeight);

    setBackgroundProps((prev) => ({
      ...prev,
      x: newX,
      y: newY,
    }));
  };
  return (
    <div className="ml-2">
      <div
        className="h-full w-full border-2 ml-4 border-blue-800"
        style={{
          width: `${containerSize.width}px`,
          height: `${containerSize.height}px`,
        }}
      >
        <Stage
          ref={stageRef}
          width={containerSize.width}
          height={containerSize.height}
          onMouseDown={handleDeselect}
          style={{
            backgroundImage,
          }}
          pixelRatio={window.devicePixelRatio || 1}
        >
          <Layer>
            {backgroundImage && (
              <>
                <Image
                  ref={backgroundRef}
                  image={backgroundImage}
                  x={backgroundProps.x}
                  y={backgroundProps.y}
                  width={backgroundImage.width * backgroundProps.scale}
                  height={backgroundImage.height * backgroundProps.scale}
                  draggable={isDraggable}
                  onDragMove={handleDragMove}
                  onDblClick={handleDoubleClick}
                  onClick={(e) => {
                    e.cancelBubble = true;
                    setIsBackgroundSelected(true);
                    setIsSelected(false);
                  }}
                  onTransformEnd={(e) => {
                    const node = backgroundRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    setBackgroundProps((prev) => ({
                      ...prev,
                      x: node.x(),
                      y: node.y(),
                      scale: Math.min(scaleX, scaleY),
                    }));

                    node.scaleX(1);
                    node.scaleY(1);
                  }}
                />
                <Transformer
                  ref={backgroundTrRef}
                  anchorSize={6}
                  enabledAnchors={[
                    "top-left",
                    "top-right",
                    "bottom-left",
                    "bottom-right",
                  ]}
                  rotateEnabled={false}
                  boundBoxFunc={(oldBox, newBox) => {
                    if (newBox.width < 30 || newBox.height < 30) {
                      return oldBox;
                    }
                    return newBox;
                  }}
                />
              </>
            )}
            {modelImage && (
              <>
                <Image
                  ref={imageRef}
                  image={modelImage}
                  draggable
                  x={containerSize.width * 0.1}
                  y={containerSize.height * 0.1}
                  width={
                    (containerSize.height * 0.8 * modelImage.width) /
                    modelImage.height
                  }
                  height={containerSize.height * 0.8}
                  shadowColor={shadowProps.shadowColor}
                  shadowBlur={shadowProps.shadowBlur}
                  shadowOffsetX={shadowProps.shadowOffsetX}
                  shadowOffsetY={shadowProps.shadowOffsetY}
                  shadowOpacity={shadowProps.shadowOpacity}
                  onClick={(e) => {
                    e.cancelBubble = true;
                    setIsSelected(true);
                  }}
                  onTap={(e) => {
                    e.cancelBubble = true;
                    setIsSelected(true);
                  }}
                />
              </>
            )}
            <Transformer
              ref={trRef}
              anchorSize={6}
              enabledAnchors={[
                "top-left",
                "top-right",
                "bottom-left",
                "bottom-right",
              ]}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                if (newBox.width < 30 || newBox.height < 30) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          </Layer>
        </Stage>
      </div>
      <div className="mt-4">
        <div className="flex items-center space-x-4 space-y-2 ml-11">
          <button
            onClick={() => {
              setShowShadowDropdown(!showShadowDropdown);
              handleTabChange();
            }}
            className="px-4 py-2 bg-gray-800 text-white rounded-lg"
          >
            {showShadowDropdown ? "Hide Shadow Options" : "Show Shadow Options"}
          </button>
        </div>

        {showShadowDropdown && (
          <>
            <div className="absolute mt-4 space-y-4 space-x-2 -ml-4 bg-gray-800 z-10 border-2 rounded-md border-dashed  w-[335px]">
              {/* <div className="flex items-center mt-2 ml-7 space-x-2">
                <button
                  onClick={() => handleTabChange("dropShadow")}
                  className={`px-2 py-2 rounded-md ${activeTab === "dropShadow"
                    ? "bg-purple-800 text-white"
                    : "bg-gray-800 text-gray-300"
                    }`}
                >
                  Drop Shadow
                </button>
                <button
                  onClick={() => handleTabChange("aiAngle")}
                  className={`px-2 py-2 rounded-md ${activeTab === "aiAngle"
                    ? "bg-purple-800 text-white"
                    : "bg-gray-800 text-gray-300"
                    }`}
                >
                  AI Shadow
                </button>
              </div> */}
              {activeTab === "dropShadow" && (
                <>
                  <div className="m-2">
                    <label htmlFor="horizontal">Horizontal Offset:</label>
                    <input
                      id="horizontal"
                      type="range"
                      min={-50}
                      max={50}
                      value={shadowProps.shadowOffsetX}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowOffsetX: parseInt(e.target.value, 10),
                        }))
                      }
                      className="ml-2"
                    />
                    <span className="ml-2 text-white">
                      {shadowProps.shadowOffsetX}px
                    </span>
                  </div>

                  <div className="m-2">
                    <label htmlFor="vertical" className="">
                      Vertical Offset:
                    </label>
                    <input
                      id="vertical"
                      type="range"
                      min={-50}
                      max={50}
                      value={shadowProps.shadowOffsetY}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowOffsetY: parseInt(e.target.value, 10),
                        }))
                      }
                      className="ml-6"
                    />
                    <span className="ml-2 text-white">
                      {shadowProps.shadowOffsetY}px
                    </span>
                  </div>

                  <div className="m-2">
                    <label htmlFor="blur" className="">
                      Blur:
                    </label>
                    <input
                      id="blur"
                      type="range"
                      min={0}
                      max={100}
                      value={shadowProps.shadowBlur}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowBlur: parseInt(e.target.value, 10),
                        }))
                      }
                      className="ml-24"
                    />
                    <span className="ml-2 text-white">
                      {shadowProps.shadowBlur}
                    </span>
                  </div>

                  <div className="m-2">
                    <label htmlFor="opacity" className="">
                      Opacity:
                    </label>
                    <input
                      id="opacity"
                      type="range"
                      min={0}
                      max={1}
                      step={0.01}
                      value={shadowProps.shadowOpacity}
                      onChange={(e) =>
                        setShadowProps((prev) => ({
                          ...prev,
                          shadowOpacity: parseFloat(e.target.value),
                        }))
                      }
                      className="ml-[68px]"
                    />
                    <span className="ml-2 text-white">
                      {(shadowProps.shadowOpacity * 100).toFixed(0)}%
                    </span>
                  </div>
                  <div className="m-2">
                    <div className="flex items-center space-x-2 ml-4">
                      {[
                        "#FFFFFF",
                        "#8B0000",
                        "#0074D9",
                        "#A0522D",
                        "#000000",
                        "#008080",
                      ].map((color, index) => (
                        <button
                          key={index}
                          className="w-8 h-8 rounded-full border-2 border-white"
                          style={{
                            background: color,
                            backgroundColor: color.includes("gradient")
                              ? undefined
                              : color,
                          }}
                          onClick={() =>
                            setShadowProps((prev) => ({
                              ...prev,
                              shadowColor: color.includes("gradient")
                                ? "transparent"
                                : color,
                            }))
                          }
                        />
                      ))}
                      <div className="relative">
                        <button
                          className="w-8 h-8 rounded-full border-2 mt-1 border-white"
                          style={{
                            background:
                              "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)",
                          }}
                          onClick={() =>
                            document.getElementById("customColorPicker").click()
                          }
                        ></button>
                        <input
                          type="color"
                          id="customColorPicker"
                          className="absolute inset-0 w-0 h-0 opacity-0"
                          onChange={(e) =>
                            setShadowProps((prev) => ({
                              ...prev,
                              shadowColor: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeTab === "aiAngle" && (
                <></>
                // <>
                //   <div className="mt-2 bg-gray-800 rounded-md text-white">
                //     <div className="mb-2">
                //       <label>Light Direction:</label>
                //       <input
                //         type="range"
                //         min="0"
                //         max="360"
                //         // value={groundShadowProps.shadowAngle}
                //         // onChange={(e) =>
                //         //   updateGroundShadow(parseInt(e.target.value, 10))
                //         // }
                //         className="ml-6"
                //       />
                //       <span className="ml-2">
                //         {/* {groundShadowProps.shadowAngle}°
                //          */}
                //         0°
                //       </span>
                //     </div>
                //   </div>
                //   <div className="mt-2 mb-2">
                //     <label>Angle Opacity:</label>
                //     <input
                //       type="range"
                //       min="0"
                //       max="1"
                //       step="0.01"
                //       // value={groundShadowProps.shadowOpacity}
                //       // onChange={(e) =>
                //       //   setGroundShadowProps((prev) => ({
                //       //     ...prev,
                //       //     shadowOpacity: parseFloat(e.target.value),
                //       //   }))
                //       // }
                //       className="ml-6"
                //     />
                //     <span className="ml-2">
                //       0%
                //       {/* {(groundShadowProps.shadowOpacity * 100).toFixed(0)}
                //       % */}
                //     </span>
                //   </div>
                // </>
              )}
            </div>
          </>
        )}
        <button
          onClick={handleDownload}
          className="mt-4 px-4 py-2 ml-[68px] bg-blue-500 text-white rounded-lg"
        >
          Merge Image
        </button>
      </div>
    </div>
  );
};
const ImageProcessor = ({
  title,
  images,
  modelImages,
  uploadTitle,
  onFaceSelect,
  onModelSelect,
  onFaceUpload,
  onModelUpload,
  onRun,
  selectedFace,
  selectedModel,
  appliedFilter,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
}) => {
  const [activeTab, setActiveTab] = useState("target");
  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllModelImages, setShowAllModelImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [isResizeOpen, setResizeOpen] = useState(false);

  const resizeOptions = [
    { icon: Image, label: "Original", size: { width: 450, height: 600 } },
    { icon: Square, label: "Square", size: { width: 400, height: 400 } },
    { icon: Smartphone, label: "Portrait", size: { width: 400, height: 500 } },
    { icon: Monitor, label: "Landscape", size: { width: 500, height: 400 } },
  ];

  const [selectedSize, setSelectedSize] = useState(resizeOptions[0].size);
  const [modelImage, setModelImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentModelImageIndex, setCurrentModelImageIndex] = useState(0);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const [isDownloadVisible, setIsDownloadVisible] = useState(true);
  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);
  const stageRef = useRef();
  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllImages(false);
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllModelImages(false);
  };

  const handleShowMoreFaces = () => {
    setShowAllImages(true);
  };

  const handleShowMoreModels = () => {
    setShowAllModelImages(true);
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleFaceReset = () => {
    setFaceImage({});
  };

  const handleModelReset = () => {
    setModelImage({});
  };

  return (
    <div
      className={`${isSidebarOpen ? "" : ""} bg-[#222226] transition-all duration-200 w-full h-full rounded-xl`}
    >
      <div className="flex flex-col h-full lg:flex-row">
        {/* {isSectionVisible && ( */}
        <div className="flex flex-col px-2 ml-6 py-4 overflow-auto  w-[740px] h-screen">
          {/* Tabs */}
          <div className="flex justify-between border-b border-gray-700">
            <button
              className={`px-6 py-3 text-base transition-colors ${
                activeTab === "target"
                  ? "text-white border-b-2 border-white"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("target")}
            >
              {title}
            </button>
            <button
              className={`px-6 py-3 text-base transition-colors ${
                activeTab === "source"
                  ? "text-white border-b-2 border-white"
                  : "text-gray-400 hover:text-gray-300"
              }`}
              onClick={() => setActiveTab("source")}
            >
              {uploadTitle}
            </button>
          </div>
          {/* Tab content */}
          <div className="flex gap-8">
            {activeTab === "target" && (
              <div className="w-full rounded-lg p-4">
                <div className=" w-[200px] h-[200px] mb-4 mx-auto">
                  {faceImage.newImageUrl ? (
                    <img
                      src={faceImage.newImageUrl}
                      alt="preview"
                      className="w-full h-full object-contain rounded-lg"
                    />
                  ) : (
                    <FileUploader handleImageUpload={handleFaceImageUpload} />
                  )}
                </div>
                <div className="flex  mb-2 overflow-auto">
                  <div className="flex items-center gap-2  ">
                    {images
                      .slice(currentImageIndex, currentImageIndex + 3)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img}
                          className={`w-16 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                            selectedFace === img
                              ? "border-2 border-yellow-400"
                              : ""
                          }`}
                          alt={`Filter ${currentImageIndex + index + 1}`}
                          onClick={() => handleFaceImageSelect(img)}
                        />
                      ))}
                    <button
                      className="w-16 h-16 shrink-0 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                      onClick={handleShowMoreFaces}
                    >
                      <span className="text-sm">Show More</span>
                    </button>
                  </div>
                </div>
                {faceImage.newImageUrl && (
                  <div className="flex justify-center">
                    <button
                      onClick={handleFaceReset}
                      className="bg-[#333537] px-12 mt-2 py-2 rounded-lg text-white uppercase"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            )}

            {activeTab === "source" && (
              <div className="w-full rounded-lg p-4">
                <div className="w-[200px] h-[200px] mb-4 mx-auto">
                  {modelImage.newImageUrl ? (
                    <img
                      src={modelImage.newImageUrl}
                      alt="preview"
                      className="w-full h-full object-contain rounded-lg"
                    />
                  ) : (
                    <FileUploader handleImageUpload={handleModelImageUpload} />
                  )}
                </div>
                <div className="flex justify-center mb-2">
                  <div className="flex items-center gap-2">
                    {modelImages
                      .slice(currentModelImageIndex, currentModelImageIndex + 3)
                      .map((img, index) => (
                        <img
                          key={index}
                          src={img}
                          className={`w-16 h-16 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                            selectedModel === img
                              ? "border-2 border-yellow-400"
                              : ""
                          }`}
                          alt={`Model ${currentModelImageIndex + index + 1}`}
                          onClick={() => handleModelImageSelect(img)}
                        />
                      ))}
                    <button
                      className="w-16 h-16 shrink-0 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                      onClick={handleShowMoreModels}
                    >
                      <span className="text-sm">Show More</span>
                    </button>
                  </div>
                </div>
                {modelImage.newImageUrl && (
                  <div className="flex justify-center">
                    <button
                      onClick={handleModelReset}
                      className="bg-[#333537] px-12 mt-2 py-2 rounded-lg text-white uppercase"
                    >
                      Clear
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-6">
            <ul className="-ml-4 flex flex-col gap-y-4 text-white">
              <li>
                <a>Background Image</a>
              </li>
              <li>
                <a>Add shadow</a>
              </li>
              <li className="flex flex-row">
                <a>Generate Background</a>{" "}
                <img src={Crown} className="ml-1 w-4 h-4" />
              </li>
              <li>
                <a>Blur Background</a>
              </li>
              <li className="relative">
                <a
                  className="cursor-pointer text-white  hover:text-gray-400"
                  onClick={() => setResizeOpen(!isResizeOpen)}
                >
                  Resize
                </a>
                {isResizeOpen && (
                  <div className="absolute mt-2 bg-[#333537] text-white -top-48 shadow-lg rounded-lg p-4 -ml-2 w-80 z-10">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-lg font-semibold">Resize</h3>
                      <button
                        className="text-gray-400 hover:text-gray-200 -mt-6"
                        onClick={() => setResizeOpen(false)}
                      >
                        ✕
                      </button>
                    </div>
                    <div className="flex flex-row -mt-3 ml-24 justify-between">
                      <div className="flex flex-col items-center mb-4 text-sm">
                        <div>Width</div>
                        <button className="bg-gray-200 text-black w-12 h-4 flex justify-center items-center rounded">
                          <input
                            type="number"
                            disabled
                            value={200}
                            className="bg-gray-200 text-black w-16 text-center rounded"
                          />
                        </button>
                      </div>
                      <div className="flex flex-col  items-center mb-4 text-sm">
                        <div>Height</div>
                        <button className="bg-gray-200 text-black w-12 h-4 flex justify-center items-center rounded">
                          <input
                            type="number"
                            disabled
                            value={200}
                            className="bg-gray-200 text-black w-16 text-center rounded"
                          />
                        </button>
                      </div>
                      <div className="flex flex-col justify-between items-center mb-4 text-sm">
                        <div>Ratio</div>
                        <button className="bg-gray-200 text-black w-8 h-8 flex justify-center items-center rounded">
                          <LockKeyhole className="w-4 h-4 text-black" />
                        </button>
                      </div>
                    </div>
                    <div className="w-72 h-[2px] bg-white mb-3 -ml-2"></div>
                    <ul className="space-y-2 w-full sm:w-[330px] px-4 -ml-8">
                      {resizeOptions.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => setSelectedSize(option.size)}
                          className="flex items-center p-1 bg-gray-700 rounded hover:bg-gray-600 cursor-pointer transition duration-200 ease-in-out"
                        >
                          <option.icon className="w-8 h-8 mr-4 text-gray-300 flex-shrink-0" />

                          {/* Text Content */}
                          <div className="flex flex-row flex-grow">
                            <span className="text-white font-medium">
                              {option.label}
                            </span>
                            <span className="text-sm text-gray-400 ml-14">
                              {`${option.size.width}×${option.size.height}`}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
        {/* // )} */}
        {/* <div className="absolute flex justify-center gap-4 mb-4 mt-80">
                    {!isSectionVisible && (
                        <button
                            onClick={() => setIsSectionVisible(true)}
                            className="bg-[#222226] px-3 py-2 h-max rounded text-white"
                        >
                            <ChevronRight />
                        </button>
                    )}
                    {isSectionVisible && (
                        <div className="left-[360px]  absolute">
                            <button
                                onClick={() => setIsSectionVisible(false)}
                                className="bg-[#222226] h-max px-3 py-2 rounded text-white"
                            >
                                <ChevronLeft className="ml-2" />
                            </button>
                        </div>
                    )}
                </div> */}
        <div className="w-full bg-black">
          <div className="flex flex-col items-center justify-center md:flex-row gap-4">
            <div className="w-full md:w-1/2 relative rounded-lg py-4">
              <h2 className="text-lg text-white text-center mb-4">
                Output Image
              </h2>
              <div className="w-full mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex  flex-col items-center justify-center">
                      <img src={DemoImage} className="text-gray-300" />
                      <h1 className="text-xl text-yellow-400">
                        Generation in Process
                      </h1>
                    </div>
                    <div className="loader"></div>
                  </div>
                ) : appliedFilter ? (
                  <>
                    <div className="flex-grow w-full md:w-[70%]">
                      <EditableImage
                        backgroundSrc={faceImage}
                        modelSrc={appliedFilter}
                        setStageRef={(ref) => (stageRef.current = ref)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="bg-[#333537] p-4 h-full flex flex-col items-center justify-center rounded-lg">
                    <img src={DemoImage} className="text-gray-300" />
                    <h1 className="text-sm text-gray-500">
                      Nothing to see here yet!
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className=" flex justify-center gap-4  mb-4 mt-80">
                    {isDownloadVisible && (
                        <div className="absolute left-[78%] ">
                            <button
                                onClick={() => setIsDownloadVisible(false)}
                                className="bg-[#222226] px-3 py-2 rounded text-white"
                            >
                                <ChevronLeft />
                            </button>
                        </div>
                    )}
                    {!isDownloadVisible && (
                        <div className="absolute left-[97%]">
                            <button
                                onClick={() => setIsDownloadVisible(true)}
                                className="absolute bg-[#222226] px-3 py-2 rounded text-white"
                            >
                                <ChevronRight />
                            </button>
                        </div>
                    )}
                </div> */}

        {/* {isDownloadVisible && ( */}
        <div className="w-[600px] mt-8">
          <div className="mt-8 px-8 flex border-b flex-col justify-center gap-2 w-full">
            <div className=" w-full">
              <button
                onClick={onRun}
                disabled={
                  !selectedFace.blobImage ||
                  !selectedModel.blobImage ||
                  isRunning
                }
                className="text-white w-full bg-[#333537] py-3 px-8 rounded-md cursor-pointer hover:bg-[#474a4d] transition-colors flex gap-2 items-center justify-center"
              >
                <Sparkles size={16} />
                <span className="text-sm">
                  {isRunning ? "Generating..." : "Generate"}
                </span>
              </button>
              <p className="text-gray-500 text-sm mt-2">Uses 2 Credit</p>
            </div>
            <div>
              <DownloadButton imageUrl={appliedFilter} />
              <p className="text-gray-500 text-sm mt-2">Uses 1 Credit</p>
            </div>
          </div>
          <div className="px-8 mt-8">
            <h1 className="text-sm text-white">SKU ID:</h1>
            <div className="border w-full px-2 py-2 mt-3 rounded-sm">
              <input
                type="text"
                placeholder="Enter your ID number"
                className="bg-transparent placeholder:text-sm h-full text-sm w-full text-white"
              />
            </div>
            <button className=" bg-gradient-to-r from-[#5345E6] to-[#613AB1] mt-3 text-white px-8 py-1 rounded-sm">
              Save
            </button>
          </div>
        </div>
        {/* )} */}
        {/* Your existing modals remain the same */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}

        {showAllImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-[#222226] rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllImages(false)}
              >
                ×
              </button>
              <h3 className="text-xl text-white mb-6">Choose a {title}</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedFace === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Filter ${index + 1}`}
                    onClick={() => handleFaceImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {showAllModelImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllModelImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6">
                Choose a {uploadTitle}
              </h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {modelImages.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedModel === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Model ${index + 1}`}
                    onClick={() => handleModelImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageProcessor;
