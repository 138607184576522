import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { postFaceSwap, pollForTaskFinish } from "../../../../api/modelsApi.jsx";
import { taskStatus } from "../../../../config/constants.js";
import ImageProcessor from "../ImageProcessor-generator.jsx";
import { AlertMessage } from "../../../Utils/AlertMessage.jsx";
import { updateTabState } from "../../../../redux/studioSlice.js";

const ModelGenerator = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.studio.BG_GEN);

  const [appliedFilter, setAppliedFilter] = useState(
    state?.appliedFilter || null,
  );
  const [warningMessage, setWarningMessage] = useState(
    state?.warningMessage || "",
  );
  const [isDownloadReady, setIsDownloadReady] = useState(
    state?.isDownloadReady || false,
  );
  const [alertVisible, setAlertVisible] = useState(
    state?.alertVisible || false,
  );
  const [isRunning, setIsRunning] = useState(state?.isRunning || false);

  const handleRun = async () => {
    if (isRunning) {
      setWarningMessage("Task already in progress.");
      setAlertVisible(true);
      return;
    }

    setIsRunning(true);
    try {
      const bgGenResponse = await postFaceSwap();
      const taskId = faceSwapResponse.task_id;
      let statusObj = await pollForTaskFinish(taskId);

      if (statusObj.status === taskStatus.SUCCESS) {
        setAppliedFilter(statusObj.output);
        setIsDownloadReady(true);
        dispatch(
          updateTabState({
            tabId: "MODEL_GEN",
            newState: {
              appliedFilter: statusObj.output,
              isDownloadReady: true,
            },
          }),
        );
      } else if (
        statusObj.status === taskStatus.FAILED ||
        statusObj.status === taskStatus.EMPTY_OUTPUT
      ) {
        throw new Error("Failed to process image!");
      } else {
        throw new Error(`Unknown task status: ${statusObj.status}`);
      }
    } catch (error) {
      setWarningMessage(error.message);
      setAlertVisible(true);
    } finally {
      setIsRunning(false);
    }
  };

  return (
    <>
      <ImageProcessor
        Header=""
        title="Describe your Model"
        uploadTitle="Model Image"
        gen="model"
        onRun={handleRun}
        appliedFilter={appliedFilter}
        isRunning={isRunning}
        isDownloadReady={isDownloadReady}
        type_run=""
      />
      {alertVisible && warningMessage && (
        <AlertMessage
          message={warningMessage}
          onClose={() => {
            setAlertVisible(false);
            dispatch(
              updateTabState({
                tabId: "FACE_SWAP",
                newState: { alertVisible: false },
              }),
            );
          }}
        />
      )}
    </>
  );
};

export default ModelGenerator;
