import { ZoomIn, ZoomOut, CornerUpLeft, CornerUpRight } from "lucide-react";

export default function ImagePlaceholder() {
  return (
    <div className="relative w-full h-full">
      <div className="bg-grayBG p-4 h-full w-full flex flex-col items-center justify-center rounded-lg"></div>

      {/* Control buttons container */}
      <div className="absolute -right-10 top-2 flex flex-col gap-4">
        {/* Zoom In */}
        <button className="w-8 h-8 bg-black rounded-lg flex items-center justify-center hover:bg-opacity-80">
          <ZoomIn size={20} className="text-white" />
        </button>

        {/* Zoom Out */}
        <button className="w-8 h-8 bg-black rounded-lg flex items-center justify-center hover:bg-opacity-80">
          <ZoomOut size={20} className="text-white" />
        </button>

        {/* Divider line */}
        <div className="h-px w-full bg-gray-600 my-2"></div>

        {/* Rotate Left */}
        <button className="w-8 h-8 bg-black rounded-lg flex items-center justify-center hover:bg-opacity-80">
          <CornerUpLeft size={20} className="text-white" />
        </button>

        {/* Rotate Right */}
        <button className="w-8 h-8 bg-black rounded-lg flex items-center justify-center hover:bg-opacity-80">
          <CornerUpRight size={20} className="text-white" />
        </button>
      </div>
    </div>
  );
}
