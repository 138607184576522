import React from "react";
import BannerCard from "./BannerCard";
import HeadImage from "../../assets/images/Head.svg";
import ExerciseImage from "../../assets/images/Exercise.svg";
import Catlogue from "../../assets/images/catalogue.png";
import headingbg from "../../assets/images/headingbg.svg";

const Banner = () => {
  const mainheading = "Welcome to Nexusware";
  const description = "Achieve more with AI Start your transformation now";
  const cardsData = [
    {
      id: 1,
      imageSrc: HeadImage,
      title: "Apparel Cataloguing",
      description:
        "AI tools for Face Swap, Background Editing, Apparel Swap, and SEO-friendly Cloth Description creation, boosting efficiency.",
      link: "/dashboard/app",
    },
    {
      id: 2,
      imageSrc: ExerciseImage,
      title: "Image Engineering",
      description:
        "Advanced tools for cropping, resizing, and aspect ratio adjustments, ensuring platform-optimized, high-quality visuals.",
      link: "#/",
    },
    {
      id: 3,
      imageSrc: Catlogue,
      title: "Cataloguer",
      description:
        "Transforms clothing images into AI-generated model visuals, showcasing diverse styles and poses, eliminating traditional photoshoots.",
      link: "/dashboard/Catalogue",
    },
    {
      id: 4,
      imageSrc: HeadImage,
      title: "Image to Video",
      description:
        "Creates dynamic videos from images, showcasing apparel in motion for an engaging, interactive shopping experience.",
      link: "/dashboard/image-to-video",
    },
    {
      id: 5,
      imageSrc: HeadImage,
      title: "Product Cataloguing (Coming Soon)",
      description:
        "Product Cataloguing leverages AI for background editing, image engineering, and product try-on, enabling businesses to showcase accessories, footwear, and furniture with creative versatility, precision, and stunning visual appeal.",
      link: "/dashboard/product-catalog",
    },
  ];

  return (
    <div className="min-h-screen bg-[#222226] py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-[1500px] mx-auto">
        {/* Header Section */}
        <div className="relative text-center mb-12">
          <h1 className="text-4xl font-bold text-white mb-4 relative z-10">
            {mainheading}
          </h1>
          <img
            src={headingbg}
            alt="heading background"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-auto"
          />
          <p className="text-lg text-gray-400 mt-4">{description}</p>
        </div>

        {/* Cards Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {cardsData.map((card) => (
            <BannerCard
              key={card.id}
              id={card.id}
              imageSrc={card.imageSrc}
              title={card.title}
              description={card.description}
              link={card.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;
