import React from "react";
import {
  AlignLeft,
  Tag,
  Info,
  Users,
  Lock,
  Palette,
  Shirt,
  Ruler,
  Calendar,
  Grid,
  Droplets,
} from "lucide-react";

const GarmentDetailsDisplay = ({ garmentDetails }) => {
  if (!garmentDetails) return null;
  const { full_description, product_details, tags } = garmentDetails;

  const detailIcons = {
    audience: Users,
    closure: Lock,
    color: Palette,
    fabric: Shirt,
    fit: Ruler,
    fitting: Ruler,
    occasion: Calendar,
    pattern: Grid,
    texture: Droplets,
  };

  return (
    <div className=" text-white ">
      {/* Full Description Section */}
      <div className="mb-6">
        <div className="flex items-center mb-2">
          <AlignLeft className="w-5 h-5 mb-2 text-purple-600 mr-2" />
          <h4 className="text-lg font-semibold ">Full Description</h4>
        </div>
        <p className="text-gray-400">{full_description}</p>
      </div>

      {/* Product Details Section */}
      <div className="mb-6">
        <div className="flex items-center mb-2">
          <Info className="w-5 h-5 mb-2 text-purple-600 mr-2" />
          <h4 className="text-lg font-semibold">Product Details</h4>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(product_details).map(([key, value]) => {
            const IconComponent = detailIcons[key] || Info;
            return (
              <div
                key={key}
                className="bg-[#181818] p-3 rounded flex items-center"
              >
                <IconComponent className="w-5 h-5 text-purple-600 mr-2" />
                <div>
                  <span className="font-medium text-purple-700 capitalize">
                    {key}:{" "}
                  </span>
                  <span className="text-gray-700">
                    {value && value !== "null" ? value : "Not specified"}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Tags Section */}
      <div>
        <div className="flex items-center mb-2">
          <Tag className="w-5 h-5 mb-2 text-purple-600 mr-2" />
          <h4 className="text-lg font-semibold ">Tags</h4>
        </div>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="bg-purple-100 text-purple-800 px-2 py-1 rounded-full text-sm"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GarmentDetailsDisplay;
