import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import { login } from "../../api/authApi";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.svg";
import GoogleAuthButton from "./GoogleAuthButton";

const Login = () => {
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const navigate = useNavigate();

  const validateForm = () => {
    const formErrors = {};
    if (!email.trim()) formErrors.email = "Email is required";
    if (!password.trim()) formErrors.password = "Password is required";
    return formErrors;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    try {
      if (Object.keys(formErrors).length !== 0) return;

      setIsLoggingIn(true);

      const response = await login({
        email: email,
        password: password,
      });

      if (response.success) {
        const userProfile = response.data.user;
        if (userProfile.is_verified) {
          navigate("/dashboard/home");
        } else {
          navigate("/dashboard/home");
        }
      } else if (response.error) {
        setError("Invalid Credential!");
        toast.error("Invalid Credential!");
      } else {
        setError(
          "Couldn't login, Unknown Error, please contact support@devnex.in.",
        );
      }
    } catch (err) {
      console.error("Login failed:", err);
      setError(
        "Couldn't login, Unknown Error, please contact support@devnex.in.",
      );
      toast.error(
        "Couldn't login, Unknown Error, please contact support@devnex.in.",
      );
    } finally {
      setIsLoggingIn(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#222226] flex flex-col items-center justify-center px-4">
      <div className="w-full max-w-md">
        {/* Logo Section */}
        <div className="flex justify-center mb-8">
          <img src={logo} alt="Logo" className="h-20 w-auto" />
        </div>

        {/* Login Form Card */}
        <div className="bg-[#333537] rounded-xl shadow-lg p-8">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-semibold text-white mb-2">
              Welcome back!
            </h2>
            <p className="text-gray-400">
              Please enter your details to sign in
            </p>
          </div>

          <form onSubmit={handleLogin} className="space-y-6">
            {/* Email Field */}
            <div>
              <p className="block text-sm font-medium text-gray-200 mb-2">
                Email
              </p>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                placeholder="Enter your email"
                required
              />
            </div>

            {/* Password Field */}
            <div>
              <p className="block text-sm font-medium text-gray-200 mb-2">
                Password
              </p>
              <div className="relative">
                <input
                  type={showLoginPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 bg-[#222226] border border-gray-700 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent text-white placeholder-gray-500"
                  placeholder="Enter your password"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowLoginPassword(!showLoginPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300"
                >
                  {showLoginPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>

            {/* Remember Me and Forgot Password */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-700 bg-[#222226] text-purple-600 focus:ring-purple-600"
                />
                <p className="ml-2 text-sm text-gray-300">Remember me</p>
              </div>
              <Link
                to="/forgot"
                className="text-sm text-purple-500 hover:text-purple-400"
              >
                Forgot password?
              </Link>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isLoggingIn}
              className="w-full bg-gradient-to-r from-[#5345E6] to-[#613AB1] text-white py-2 px-4 rounded-lg hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoggingIn ? "Signing in..." : "Sign in"}
            </button>

            {/* Divider */}
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-700"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 text-gray-400 bg-[#333537]">
                  Or continue with
                </span>
              </div>
            </div>

            {/* Google Sign In Button */}
            <GoogleAuthButton />

            <div className="text-center text-sm text-gray-400">
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="text-purple-500 hover:text-purple-400"
              >
                Sign up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
